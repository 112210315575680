.client-top-overview-name-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
}
.client-top-overview-name-btn .title-client-all{
    color: #454F59;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26.84px;
}
.custom-dropdown-new .checkbox {
    display: inline-block;
    border: 1px solid #405BFF !important;
    color: #FFFFFF;
    height: 14px !important;
    position: relative;
    width: 14px !important;
    padding: 0 !important;
    vertical-align: -5px;
    border-radius: 50% !important;
    min-width: 14px;
  }
  .time-client-outer.client-top-overview-outer{
    margin-top: 30px;
    padding-left: 42px;
    padding-right: 70px;
  }
  .top-filter-client-inner {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;
    margin-bottom: 24px;
    align-items: flex-end;
  }
  .top-filter-client-sort .custom-dropdown {
    padding-right: 0;
}
  .top-filter-client-inner-left {
    display: flex;
    grid-gap: 14px;
    align-items: flex-end;
   }
   .top-filter-client-status label{
    color: #282828;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 15.86px;
    letter-spacing: 0.5px;
    margin-bottom: 7px;
    text-align: left;
    display: block;
   }
   .top-filter-client-sort .custom-dropdown-new {
    width: 221px;
    height: 150px;
    padding: 14px 12px !important;
    left: auto;
    right: 0;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
    border: none !important;
}
   .top-filter-client-status .custom-dropdown-user-new-outer .selected-option.custom-dropdown-user-new {
    border-radius: 5px !important;
    border: 0.2px solid rgba(106, 121, 136, 0.70) !important;
    background: #FFF;
    min-width: 194px !important;
    height: 43px !important;
    color: #78828D;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    letter-spacing: 0.5px;
}
.top-filter-client-sort .custom-dropdown-new ul li,
.top-filter-client-status .custom-dropdown-new ul li {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    color: #282828;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    letter-spacing: 1px;
    border-bottom: 0.5px solid #D8DDE1;
    padding-bottom: 13px !important;
    margin-bottom: 13px !important;
    padding-left: 11px !important;
    padding-right: 11px !important;
    padding-top: 0 !important;
}
.top-filter-client-sort .custom-dropdown-new ul li:last-child,
.top-filter-client-status .custom-dropdown-new ul li:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border: none !important;
}
.top-filter-client-status .custom-dropdown-new ul li{
    text-align: left;
}
.top-filter-client-search input.search-input {
    width: 318px;
    height: 45px;
    /* width: 100%; */
}
.top-filter-client-sort .selected-option.custom-dropdown-user-new{
    width: 100%;
    min-width: 149px;
    height: 43px !important;
    border-radius: 5px !important;
    border: 0.2px solid rgba(106, 121, 136, 0.70) !important;
    background: #FFF;
    color: #6479F8;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.96px;
    letter-spacing: 0.5px;
    background-image: url('../../images/icons/updown.svg') !important;
}
  .custom-dropdown-new .checkbox.checked {
    background: #405BFF !important;
    border-color: #405BFF !important;
  }
  .custom-dropdown-new .checkbox.checked::before {
    content: ""; /* Clear default content */
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8' fill='none'%3E%3Cpath d='M1 4L3.5 6.5L9 1' stroke='white' strokeWidth='1.4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center; /* Center the SVG */
    position: absolute; /* Position the pseudo-element */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1; /* Show the tick mark */
  }
  .client-inner-details{
    padding-left: 31px;
    padding-right: 44px;
  }
  .client-details-overview-outer {
    padding-left: 0px;
}
.client-details-overview-outer .listing-recent-invoice-inner {
  margin-top: 23px;
  max-width: 10000px;
  overflow: auto;
}
.client-details-overview-outer .listing-recent-invoice-inner  .p-datatable .p-datatable-thead tr th,
.client-details-overview-outer .listing-recent-invoice-inner  tbody.p-datatable-tbody>tr td{
  /* width: auto !important; */
  /* min-width: 100px !important; */
  white-space: nowrap;
}
.time-client-outer.client-top-overview-outer .client-ls-inner .ls-box{
  height: 100% !important;
}
.time-client-outer.client-top-overview-outer .client-name {
  position: static;
}
.content-outer.Client.Project.clients .new-top-flter {
  margin-top: 34px;
  margin-bottom: 37px;
}
.outer-client-overview-sec {
  display: flex;
  grid-gap: 16px;
}
.css-1xc3v61-indicatorContainer:last-child {
  display: none;
}
.invite-client-outer.invite-client-outer-client-overview {
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
}
.time-client-outer.client-top-overview-outer .client-new-btn span.icon-invoice img {
  margin-right: 0;
}
.client-professional-details-outer.project-detai-outer-iner .member-main-outter {
  padding-left: 0;
  padding-right: 0;
}
.client-professional-details-outer.project-detai-outer-iner .member-main-outter .datatable-team-maintb{
  border: .2px solid #6a7988b3;
  padding-top: 0;
  margin-top: 38px;
  border-radius: 15px;
  padding-right: 0;
  overflow: auto;
}
.client-ls-inner-no-data {
  grid-template-columns: 1fr;
}
.client-ls-inner-no-data .outter-load-table{
  font-size: 16px;
  margin-top: 30px;
}
.content-outer.Vendor.Invoices,
.content-outer.Vendor.Overview,
.content-outer.vendors {
  padding-left: 0;
  padding-right: 0;
}
a.btn-invite-client.bulk-export {
  grid-gap: 8.5px;
}
.information-inner.bottom-save-sec .total-section textarea {
  background-color: #FFFFFF;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 7px;
  width: 100%;
  height: 65px;
  padding: 8px 16px;
  font-weight: 300;
  font-size: 19px;
  line-height: 23.18px;
  letter-spacing: 0.5px;
  color: #282828;
}
.content-outer.Dashboard.vendors {
  padding-right: 37px;
  padding-left: 41px;
}
@media screen and (min-width: 991px) {
  .content-outer.Vendor.Invoices .outer-invoice-clinet-section .new-top-flter{
      margin-top: 30px;
  }
  
}
@media screen and (max-width: 991px) {
.content-outer.Dashboard.vendors {
  padding-right: 15px;
  padding-left: 15px;
}
}
  @media screen and (max-width: 550px){
    .top-filter-client-inner,
    .top-filter-client-inner-left,
    .top-filter-client-status{
        flex-wrap: wrap;
        width: 100%;
    }
    .top-filter-client-search,
    .top-filter-client-search input.search-input,
    .top-filter-client-status .custom-dropdown-user-new-outer .selected-option.custom-dropdown-user-new
    .top-filter-client-sort .selected-option.custom-dropdown-user-new,
    .top-filter-client-sort {
        width: 100% !important;
    }
    .client-top-overview-name-btn{
      flex-direction: column;
      text-align: left;
      grid-gap: 20px;
      align-items: baseline;
    }
    .outer-client-overview-sec,
    .invite-client-outer.invite-client-outer-client-overview{
      flex-direction: column !important;
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 551px){
    .client-top-overview-name-btn{
      flex-direction: column;
      grid-gap: 20px;
      align-items: baseline;
    }
  }
  @media only screen and (max-width: 1100px) and (min-width: 551px){
    .top-filter-client-inner {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .top-filter-client-sort {
        width: 100%;
        display: flex;
        justify-content: right;
    }
    .time-client-outer.client-top-overview-outer {
      padding-left: 20px;
      padding-right: 20px;
  }
  }
  @media screen and (max-width: 767px){
  .time-client-outer.client-top-overview-outer {
    /* margin-top: 20px; */
    padding-left: 20px;
    padding-right: 20px;
}
  }