.notification-icon {
  cursor: pointer;
}

.notification-dropdown {
  position: absolute;
  top: 100%;
  right: -9px;
  background-color: white;
  border-radius: 4px;
  padding: 28px 0px 0px 0px;
  width: max-content;
  min-width: 427px;
  max-width: 427px;
  text-align: left;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 0px 0px 8px rgba(106, 121, 136, 0.3);
  margin-top: 13px;
  border-radius: 10px;
  z-index: 100;
  padding-right: 0px;

}

.notification-dropdown ul {
  height: 218px;
  overflow: scroll;
  padding-right: 0px !important;
  overflow-x: hidden;
}

.noti-top-sec {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 16px;
  padding: 0px 31px 0 33px;
}

li.notification-listout.unread {
  background: #F5F6F8;
  border-radius: 0px;
}

li.notification-listout:last-child {
  border-bottom: none;
}

.notification-text {
  max-width: 227px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #282828;
  margin-bottom: 7px;
  word-break: break-word;
}

.notification-date {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  color: #6A7988;
}

.notification-icon {
  position: relative;
  display: inline-block;
}

.count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  font-size: 10px;
  font-weight: 500;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  min-width: 22px;
  min-height: 22px;
  align-items: center;
  justify-content: center;
}

.notification-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.notification-dropdown li:first-child {
  /* padding-top: 0; */
}

.notification-dropdown li {
  padding: 12px 31px 16px 33px;
  cursor: pointer;
  /* border-bottom: 0.5px solid #D8DDE1; */
  /* padding: 12px 0 39px; */
  position: relative;
}
.notification-dropdown li:after{
  content: "";
  background: #D8DDE1;
  height: 0.5px;
  width: calc(100% - 64px);
  position: absolute;
  left: 33px;
  bottom: 0;
}
.notification-dropdown li:hover {
  /* opacity: 0.8; */
}

/* check mark css in system  */
.notification-listout {
  display: flex;
  justify-content: space-between;
}

.notification-listout.hovered {
  background-color: #f0f0f0;
  /* Add the background color you want when hovering */
}

img.checkmark {
  padding-top: 5px;
}

/* the tool tip view */
.notification-dropdown:before {
  width: 15px;
  height: 15px;
  content: "";
  position: absolute;
  background: #FFFFFF;
  top: -8px;
  right: 33px;
  border-left: 0.2px solid rgba(106, 121, 136, 0.7);
  border-top: 0.2px solid rgba(106, 121, 136, 0.7);
  transform: rotate(45deg);
}

.notification-dropdown h3 {
  margin: 0;
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
  display: flex;
  color: #6A7988;
  margin-bottom: 26px;
  padding: 0px 31px 0 33px;
  background-color: #fff;
}

.notification-dropdown li:hover .view-on-hover {
  display: block;
  padding-top: 10px;
}

.view-on-hover {
  display: none;
}

.not-outer {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

button.noti-read-all {
  background: transparent;
  color: #405BFF;
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  padding: 0px !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
/* .notification-dropdown ul::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #F5F6F8;
  border-radius: 10px;
} */

/* Add a thumb */
/* .notification-dropdown ul::-webkit-scrollbar-thumb {
  background: #96A8BA;
  border-radius: 10px;
}
* {
  scrollbar-width: auto;
  scrollbar-color: #535053 #ffffff;
} */

/* Chrome, Edge, and Safari */
/* .notification-dropdown ul::-webkit-scrollbar {
  width: 8px;
}

.notification-dropdown ul::-webkit-scrollbar-track {
  background: transparent;
  position: absolute;
  right: 0;
}

.notification-dropdown ul::-webkit-scrollbar-thumb {
  background-color: #96A8BA;
  border-radius: 10px;
  border: 1px solid #ffffff;
} */

.notification-dropdown ul {
  scrollbar-width: auto;
  scrollbar-color: #96A8BA #ffffff;
}

/* Chrome, Edge, and Safari */
.notification-dropdown ul::-webkit-scrollbar {
  width: 16px;
}

.notification-dropdown ul::-webkit-scrollbar-track {
  background: #ffffff;
}

.notification-dropdown ul::-webkit-scrollbar-thumb {
  background-color: #96A8BA;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

@media screen and (min-width: 992px) {
  img.checkmark {
    padding-top: 5px;
  }

  .notification-listout {
    align-items: self-start;
  }

  .notification-dropdown {
    height: 330px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #96A8BA;
  /* Set the color of the thumb */
}

::-moz-scrollbar-track {
  width: 4px;
}
.archive-sec {
  display: flex;
  grid-gap: 7px;
  color: #282828;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  letter-spacing: 1px;
  background: transparent;
  padding: 0 !important;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .notification-dropdown h3 {
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;

  }

  .notification-dropdown ul {
    height: 100%;
    padding-bottom: 100px;
  }

  .notification-dropdown {
    border: none;
    box-shadow: none;
  }

  img.checkmark {
    padding-top: 5px;
  }

  .notification-listout {
    align-items: self-start;
  }
}

/* Notification.css */
.tooltip1 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 8px;
  /* Add appropriate margin for spacing */
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 95px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: auto;
  left: auto;
  /* transform: translateX(-50%); */
  opacity: 0;
  transition: opacity 0.3s;
  right: 0;
  bottom: -35px;
  font-size: 11px;
}

.tooltip1 .tooltiptext::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}