.profile-details .profile-photo-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 38px 40px 24px 35px;
    padding-bottom: 41px;
    border-bottom: 1px solid #E8E8E8;
}

.profile-details .profile-image img {
    width: 107px;
    height: 107px;
    border-radius: 50%;
    object-fit: contain;
}

.profile-details .profile-photo-sec {
    position: relative;
}

.profile-details .profile-pic-edit {
    position: absolute;
    bottom: -5px;
    right: 5px;
}

.profile-details .profile-type {
    font-weight: 300;
    font-size: 16px;
    line-height: 19.52px;
    letter-spacing: 1px;
    color: #282828;
    margin-bottom: 12px;
    text-align: left;
}

.profile-details .profile-status .active {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #7CC273;
    background: #D5F3D8;
    border: 0.2px solid #7CC273;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-details .profile-status .inactive {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #96A8BA;
    background: #EAEAEA;
    border: 0.2px solid #6A7988;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
}

.profile-details .profile-photo .profile-photo-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 23px;
}

.profile-details .profile-name-sec {
    font-weight: 500;
    font-size: 20px;
    line-height: 24.4px;
    letter-spacing: 1px;
    color: #282828;
    margin-bottom: 6px;
    text-align: left;
}

.profile-details .profile-pic-edit img {
    width: 29px;
    height: 29px;
}

.profile-details a.edit-profile {
    display: flex;
    width: 132px;
    height: 44px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background: #6479F8;
    border-radius: 6px;
    grid-gap: 12.75px;
}

.profile-details a.edit-profile img {
    Width: 21.23px;
    Height: 21.36px;
}

.profile-details a.delete-profile img {
    width: 18.67px;
    height: 20px;
}

.profile-details a.delete-profile {
    display: flex;
    width: 110px;
    height: 44px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08;
    letter-spacing: 0.5px;
    color: #282828;
    background: #F5F6F8;
    border: 0.2px solid #282828;
    border-radius: 6px;
    grid-gap: 14.33px;
}

.profile-details .profile-button a:hover {
    opacity: 0.8;
}

.profile-details .profile-button {
    display: flex;
    flex-direction: row;
    grid-gap: 19px;
}


.profile-details .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.profile-details .confirmation {
    background-color: #fff;
    padding: 50px;
    border-radius: 4px;
    text-align: center;
}

.profile-details .confirmation p {
    margin-bottom: 30px;
    font-size: 18px;
}

.profile-details .confirmation button {
    margin-right: 10px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #6479F8;
    color: #fff;
    cursor: pointer;
}

.profile-details .success-message {
    background-color: #6479F8;
    color: #fff;
    padding: 20px;
    text-align: center;
    margin-top: 10px;
    border-radius: 4px;
    position: absolute;
    right: 42px;
    top: 15%;
}

.profile-details .information-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21.96px;
    letter-spacing: 0.2px;
    color: #454F59;
    display: flex;
    margin-bottom: 28px;
}

.profile-details .information-outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
}

.profile-details .information {
    padding: 0 39px 0px 44px;
}

.profile-details .inner-detail label {
    font-weight: 300;
    font-size: 17px;
    line-height: 20.74px;
    display: flex;
    align-items: center;
    color: #6A7988;
}

.profile-details .inner-detail-outer {
    margin-bottom: 23px;
}

.profile-details .inner-detail label img {
    margin-right: 10px;
}

.profile-details .inner-detail {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.profile-details .inner-detail .icon {
    width: 26px;
}

.profile-details .inner-detail .icon {
    display: inline-grid;
    justify-content: center;
    margin-top: 5px;
}

.profile-details .value-field {
    font-weight: 300;
    font-size: 19px;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    color: #282828;
}

.profile-details .information-inner {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 7px;
    margin-bottom: 28px;
    margin-left: 35px;
    margin-right: 40px;
}

.profile-details .information-inner:last-child {
    border: 0;
}

.inner-detail-inner .value-field {
    position: unset;
    transform: translate(0);
    background: transparent;
}

@media only screen and (max-width: 767px) and (min-width: 220px) {
    .profile-details .profile-photo-outer {
        flex-direction: column;
        margin: 29px 15px 24px 15px;
    }

    .profile-details .tab-outer ul.tabs li {
        font-size: 17px !important;
        margin-right: 27px !important;
    }

    .profile-details .tab-outer ul.tabs li:last-child {
        margin-right: 0 !important;
    }

    .profile-details .tab-outer ul.tabs {
        padding: 28px 17px 0 17px !important;
    }

    .profile-details a.delete-profile {
        width: 132px;
    }

    .profile-details .information {
        padding: 0 !important;
    }

    .profile-details .information-inner {
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 4px;
    }

    .profile-details .information-outer {
        grid-template-columns: 1fr;
    }

    .profile-details .inner-detail {
        margin-bottom: 9px;
    }

    .profile-details .information-right .inner-detail:last-child {
        margin-bottom: 20px;
    }

    .profile-details .profile-button {
        margin-top: 52px;
    }
}

@media only screen and (max-width: 479px) and (min-width: 220px) {
    .profile-details .tab-outer ul.tabs {
        justify-content: space-between;
    }

    .profile-details .profile-button {
        margin-top: 52px;
        ;
    }
}