body {
  font-family: "halyard-display",sans-serif !important;
}
*{
  box-sizing: border-box;
}
.content-outer {
  background: #FBFBFD;
  margin-top: 100px;

}
.admin-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.outer-nav {
  width: calc(100% - 252px);
}
.sidebar-nav {
  width: 252px;
}
.inner-overview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 22px 23px;
}
.content-outer{
  padding-right: 37px;
  padding-left: 41px;
}
.graph-overview {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding-top: 15px;

}
.overview-left {
  width: calc(100% - 38%);
}
.over-right {
  width: 442px;
}
.navbar-outer {
  position: fixed;
  width: inherit;
  z-index: 99999;
  background: #fff;
}
.content-outer{
  padding-top: 79px;
}
.back-to a,
.back-to  {
  font-weight: 300;
  font-size: 18px;
  line-height: 21.96px;
  letter-spacing: 0.5px;
  color: #282828;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.back-to a:hover{
  color: #282828;
  opacity: 0.8;
}
span.back-icon {
  margin-right: 14px;
  display: flex;
  align-items: center;
}
/* UserProfile.css */

/* Styles for the modal */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.ReactModal__Content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  padding-bottom: 37px;
}

/* Styles for the confirmation message */
.confirmation {
  text-align: center;
}

.confirmation p {
  margin-bottom: 20px;
}

.confirmation button {
  margin-right: 10px;
}

/* Styles for the success message */
.success-message {
  text-align: center;
  background-color: #dff0d8;
  color: #3c763d;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
}
.ReactModal__Content.ReactModal__Content--after-open p {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
.ReactModal__Content.ReactModal__Content--after-open > div {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
}
.ReactModal__Content.ReactModal__Content--after-open > div button {
  background: #6479F8;
  font-weight: 500;
  font-size: 14px;
  line-height: 122%;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  height: 44px;
  padding: 0 30px;
}
.back-to {
  margin-top: 24px;
}
.rs-anim-fade.rs-anim-in{
  /* margin-top: 7px; */
  z-index: 99999;
}
input{
  outline: none;
}
.rs-picker-input input:focus {
  outline: none !important;
  border: none !important;
}
.rs-picker-input input:focus {
  outline: none !important;
  border: none !important;
}
.content-outer.users .security-outer.noti-outer {
  border: none;
  box-shadow: none;
}
.rs-picker-toggle-wrapper{
  outline: none;
}
body.ReactModal__Body--open {
  overflow: hidden;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover{
  outline: none !important;
  border-color: #6a7988b3 !important;
  box-shadow: none;
}
input.rs-picker-toggle-textbox{
  border: none !important;
}
select{
  outline: none !important;
}
.modal-close-invite.outter-modal-main{
  position: sticky;
  padding-top: 15px;
  z-index: 999;
  top: -6px;
}
.modal-close-invite.outter-modal-main a{
  background: #fff;
  padding: 10px;
}
.showing-result .selected-option.custom-dropdown-user-new {
  background-color: #fafafa!important;
  border: 0.5px solid #b4b4b4;
  color: #282828;
  font-size: 11px;
  font-weight: 500;
  height: 27px !important;
  min-width: 44px;
  padding: 1px 20px 4px 8px;
  width: 100%;
  background-position: calc(100% - 5px) center !important;
  background-size: 10px;
}
.showing-result .custom-dropdown-new {
  padding: 10px 5px!important;
  background-color: #fafafa !important;
}
.showing-result .custom-dropdown-new ul li{
  font-size: 10px !important;
  padding-top: 0 !important;
}
.showing-result .custom-dropdown-new ul li:first-child{
  padding-top: 10px !important;
}
tr.p-datatable-emptymessage td{
  text-align: center !important;
}
.curency-dropdown-inner .css-1fdsijx-ValueContainer {
  padding-left: 15px !important;
}
.content-outer.Time.Tracker {
  padding-left: 0;
  padding-right: 0;
}
li.previous a {
  border: none;
}
.filter-sec .date-picker-outer.date-picker-outer-all input{
  max-width: 188px !important;
  width: 154px;
  height: 43px;
  color: #78828D;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 20.74px;
  letter-spacing: 0.5px;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal.tracker-overview-modal .date-overview-sec.date-overview-sec-rem {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal.tracker-overview-modal .form-field-client.tracker-detail-dropdown input.rs-picker-toggle-textbox {
  padding-left: 40px !important;
}
.error-input,
.selected-option.custom-dropdown-user-new.error-input,
.custom-dropdown-user-new.error-input {
  border-color: red !important;
  border-width: 1px !important;
}
.the-modal-inuser .selected-option.custom-dropdown-user-new {
  margin-top: 10px;
}
button.cancel-btn {
  border-radius: 6px !important;
  border: 0.2px solid #282828 !important;
  background: #F5F6F8 !important;
  color: #282828 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open.confirmation-modal-overlay .modal-footer {
  display: flex;
  grid-gap: 20px;
  justify-content: center;
}
.ReactModal__Content.ReactModal__Content--after-open.confirmation-modal .modal-content {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.ReactModal__Content.ReactModal__Content--after-open.confirmation-modal {
  padding: 35px 25px !important;
}
.repot-tatal-box-sec .overview-icon {
  min-width: 49px;
  min-height: 49px;
}
p.recharts-tooltip-label {
  font-size: inherit !important;
  font-weight: 500 !important;
  color: #282828 !important;
}
.content-outer.Time.Off .time0ff-membername {
  min-width: auto !important;
}
/* .ReactModal__Overlay.ReactModal__Overlay--after-open.confirmation-modal-overlay .modal-footer button{
  width: 100% !important;
} */
.modal-inner-des-outer .modal-inner-graph{
  overflow: auto;
}
.admin-outer.Personnel.Reports .tab-outer-time ul.tabs {
  padding-left: 50px;
  padding-right: 50px;
}
.form-field-client-currency {
  display: flex;
  align-items: center;
  grid-gap: 0px;
}
.form-field-client-currency .currency-input{
  background: #FFFFFF;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 7px;
  font-weight: 300;
  font-size: 19px;
  line-height: 23.18px;
  letter-spacing: 0.5px;
  color: #282828;
  padding: 8px 12px;
  width: auto;
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  height: 43px;
  padding-right: 2px !important;
  display: flex;
  align-items: center;
}
.form-field-client-currency input{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
  padding-left: 0 !important;
}
.admin-outer.Dashboard input#date-range::placeholder,
.admin-outer.Dashboard input#date-range,
.admin-outer.Client.Overview input#date-range,
.admin-outer.Client.Overview input#date-range::placeholder {
  text-transform: capitalize !important;
}
.custoom-icon-calender.custoom-icon-calender-all.custom-date-range input#invoiceId, .custoom-icon-calender.custoom-icon-calender-all.custom-date-range input#invoiceId::placeholder {
  text-transform: capitalize !important;
}
.password-input-wrapper img {
  position: absolute;
  right: 12px;
  top: 50%;
  cursor: pointer;
}
.password-input-wrapper{
  position: relative;
}
.password-input-wrapper input{
  padding-right: 30px !important;
}
.ReactModal__Content .date-picker-outer.date-picker-outer-all.filter-report-date{
  position: relative !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal .date-picker-outer.date-picker-outer-all.filter-report-date button {
  top: 21px !important;
  border: 2px solid #405BFF !important;
  border-radius: 50% !important;
  background-color: transparent !important;
  width: 20px !important;
  height: 20px !important;
  line-height: normal !important;
}
.content-outer.Reporting.Overview .button-inner-report a,
.button-inner-report a {
  display: inline-flex !important;
}
.content-outer.Reporting.Overview .button-inner-report,
.button-inner-report {
  text-align: left;
}
.button-inner-report-all-currency {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  flex-wrap: wrap;
}
p.all-currency-sec-inner{
  display: flex;
  align-items: center;
  grid-gap: 5px;
}
.content-outer.Invoices.vendors{
  padding-left: 40px;
  padding-right: 40px;
}
.content-outer.Vendor.Invoices.vendors {
  padding-left: 40px;
  padding-right: 40px;
}
@media screen and (max-width: 728px){
  .curency-dropdown.filter-dashbord-calendar{
    flex-wrap: wrap;
    grid-gap: 30px;
  }
  .content-outer.Dashboard .curency-dropdown.filter-dashbord-calendar .date-picker-outer.date-picker-outer-all {
    width: 100% !important;
    background-color: transparent;
    display: flex;
    justify-content: end;
}
.custom-picker-icon.custom-picker-icon-all {
  left: auto;
  right: 170px;
}
.date-range-new{
  top: 100%;
}
}
@media screen and (max-width: 1200px){
  .curency-dropdown-inner {
      grid-gap: 18px;
      flex-wrap: wrap;
  }
}
@media screen and (min-width: 993px){
  .ReactModal__Content.ReactModal__Content--after-open.client-modal{
    min-width: 400px;
  }
}
@media screen and (max-width: 940px) and (min-width: 851px){
  .date-range-new .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
    max-width: calc(100vw - 30px);
  }
  .date-range-new .new-calendar-button {
    position: initial !important;
    bottom: 36px;
    right: 51px;
    display: flex;
    grid-gap: 32px;
    padding-bottom: 37px;
    padding-top: 30px;
    justify-content: end;
    padding-right: 30px;
}
  .date-range-new .rdrStaticRanges {
    flex-direction: row !important;
    white-space: nowrap;
    max-width: 10000px;
    overflow: auto;
    width: 100%;
}
.date-range-new .rdrDateRangePickerWrapper {
  flex-wrap: wrap;
  width: 100%;
}
.date-range-new .rdrDefinedRangesWrapper {
  width: 100% !important;
  border-top-right-radius: 10px;
  padding-top: 5px !important;
}
.date-range-new .rdrDefinedRangesWrapper .rdrStaticRange{
  padding: 15px 13px !important;
}
.date-range-new .rdrCalendarWrapper {
  width: 100%;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal{
  justify-content: center;
  flex-wrap: wrap;
}
.date-range-new .rdrMonth{
  width: 50% !important;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:last-child {
  padding-left: 25px;
}
.date-range-new .rdrDay{
  flex-basis: calc(100% / 7);
}
.date-range-new .rdrDay,
  .date-range-new span.rdrDayNumber span,
  .date-range-new .rdrStartEdge,
  .date-range-new .rdrEndEdge,
  .date-range-new .rdrInRange,
  .date-range-new span.rdrDayNumber {
    width: 100% !important;
    height: 100% !important;
    /* min-width: 100% !important;
    min-height: 100% !important; */
  }
}
@media screen and (max-width: 80px){
  .curency-dropdown-inner{
    width: 100% !important;
  }
  .curency-dropdown-inner{
    grid-gap: 10px 0 !important;
  }
}
@media screen and (max-width: 150px){
  .date-range-new .rdrMonth{
    /* width: 100% !important; */
  }
  .date-range-new .rdrDay,
  .date-range-new span.rdrDayNumber span,
  .date-range-new .rdrStartEdge,
  .date-range-new .rdrEndEdge,
  .date-range-new .rdrInRange,
  .date-range-new span.rdrDayNumber,
  .date-range-new .rdrDayStartPreview,
  .date-range-new .rdrDayEndPreview,
  .date-range-new .rdrDayInPreview {
    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
  }
  .date-range-new .rdrDays {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(7, 1fr);
}
  .date-range-new .rdrMonths.rdrMonthsHorizontal{
    /* padding: 0 20px !important; */
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:first-child{
    padding-right: 0 !important;
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:last-child{
    padding-left: 0 !important;
  }
  .date-range-new.right-align{
    top: 100%;
  }
}
@media screen and (max-width: 1200px){
  .date-range-new .rdrDay{
    flex-basis: calc(100% / 7);
    width: auto !important;
    height: 44px !important;
  }
  .date-range-new .rdrStartEdge, .date-range-new .rdrEndEdge, .date-range-new .rdrInRange{
    width: auto;
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:last-child {
    padding-left: 0;
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:first-child {
      padding-right: 0;
  }
  .date-range-new .rdrCalendarWrapper{
    padding-bottom: 100px;
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal{
    padding: 0 25px !important;
  }
  .date-range-new .rdrDay,
  .date-range-new span.rdrDayNumber span,
  .date-range-new .rdrStartEdge,
  .date-range-new .rdrEndEdge,
  .date-range-new .rdrInRange,
  .date-range-new span.rdrDayNumber,
  .date-range-new .rdrStartEdge::before,
  .date-range-new .rdrEndEdge::before,
  .date-range-new .rdrDayInPreview {
    width: auto !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:last-child {
    padding-left: 0px !important;
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:first-child {
    padding-right: 0px !important;
  }
  .date-range-new .rdrMonthAndYearWrapper {
    padding-right: 34px !important;
    padding-left: 34px !important;
}
}
@media screen and (max-width: 610px){
  .date-range-new .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
    max-width: calc(100vw - 30px);
  }
  .date-range-new .new-calendar-button {
    position: initial !important;
    bottom: 36px;
    right: 51px;
    display: flex;
    grid-gap: 32px;
    padding-bottom: 37px;
    padding-top: 30px;
    justify-content: end;
    padding-right: 30px;
}
.date-range-new .rdrDefinedRangesWrapper .rdrStaticRange {
  padding: 15px !important;
}
.date-range-new .rdrCalendarWrapper {
  padding-bottom: 0px;
}
  .date-range-new .rdrStaticRanges {
    flex-direction: row !important;
    white-space: nowrap;
    max-width: 10000px;
    overflow: auto;
    width: 100%;
}
.date-range-new .rdrDateRangePickerWrapper {
  flex-wrap: wrap;
  width: 100%;
}
.date-range-new .rdrDefinedRangesWrapper {
  width: 100% !important;
  border-top-right-radius: 10px;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:last-child {
  padding-left: 0px !important;
}
.date-range-new .rdrMonths.rdrMonthsHorizontal .rdrMonth:first-child {
  padding-right: 0px !important;
}
.date-range-new .rdrCalendarWrapper {
  width: 100%;
  align-items: center;
}
}
@media screen and (max-width: 1302px){
.invoice-outer {
  width: max-content;
}
}
@media screen and (min-width: 991px) {
  .content-outer{
    margin-top: 0;
    min-height: 100%;
  }
}
@media screen and (max-width: 991px) {
  .inner-overview{
    grid-template-columns: 1fr;

  }
  .graph-main-outer-time.graph-main-outer-time-report{
    max-width: 100000px;
    width: 100%;
    overflow: auto;
  }
  .graph-project-per-hour {
    min-width: 800px !important;
  }
  .date-range-new .rdrMonths.rdrMonthsHorizontal{
    /* padding: 0 20px !important; */
  }
  .content-outer.users {
    padding-right: 0;
}
  .content-outer{
    padding-right: 15px;
    padding-left: 15px;
  }
  /* .admin-inner{
    overflow: hidden;
  } */
  .sidebar-nav {
    width: 100%;
}
.outer-nav{
  width: 100%;
}
.admin-inner{
  flex-direction: column-reverse;

}
.sidebar-nav {
  width: 100%;
  position: absolute;
  top: 77px;
}
.inner-overview{
  grid-gap: 15px 15px;

}
.content-outer{
  background-color: #ffff;
  margin-top: 91px;
}

}
@media screen and (min-width: 1500px) {
  /* .admin-outer.dashboard .content-inner {
  width: 1199px;
  margin: 0 auto;
} */
}
@media screen and (max-width: 992px) {
  .ReactModal__Overlay{
    /* z-index: 99999; */
  }
  .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-start,
  .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-end {
    transform: translate(3.5%, 30%) !important;
  }
  
}
@media only screen and (max-width: 550px) {
  .invo-date-rangeme{
    flex-wrap: wrap;
    grid-gap: 20px;
  }
  .invo-date-range-tile{
    width: 100%;
  }
}
/**********************iPhone Landscape*************/
@media only screen and (max-width: 767px) and (min-width: 480px){
  .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-start,
  .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-end {
    transform: translate(-2%, 42%) !important;
}
}

/*************************iPad Portrait***************/
@media only screen and (max-width: 985px) and (min-width: 768px){
  .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-start,
  .rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-end {
    transform: translate(-2%, 42%) !important;
}
}
.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-start,
.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-menu.placement-top-end {
  transform: translate(-3.5%, 30%);
}
.value-field {
  /* position: absolute; */
  /* transform: translate(0, -50%); */
  background: #fff;
  padding-right: 0px; 
  z-index: 9;
  /* margin-right: 50px; */
  width: max-content;
}
.value-field.full-text{
  position: absolute;
  z-index: 9999;
  max-width: 500px;
  overflow: auto;
  transform: translate(-7px, -50%);
  overflow-y: hidden;
  padding-right: 10px;
  border-radius: 7px;
  background: #FFF!important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  /* min-height: 33px; */
  display: flex;
  align-items: center;
  text-align: left;
  padding: 3px 7px;
  word-break: break-word;
  white-space: normal;
  width: max-content;
  /* min-width: 230px; */
}
tbody.p-datatable-tbody>tr td span{
  display: inline-flex;
}
.client-inner-details tbody.p-datatable-tbody>tr td,
.client-inner-details .p-datatable .p-datatable-thead>tr>th{
  padding-right: 20px;
}
.client-inner-details .p-datatable-wrapper {
  max-width: 1000000px;
  width: 100%;
  overflow: auto;
  white-space: pre;
  border-radius: 15px;
}
.type-dropdown-em .selected-option.custom-dropdown-user-new {
  min-width: 188px;
}
.custom-dropdown-new {
  position: absolute;
  background: #fff;
  padding: 15px !important;
  width: 100%;
  left: 0;
  z-index: 99;
  top: 120%;
  max-height: 250px;
  overflow: auto;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
  border-radius: 10px;
  /* padding-right: 0; */
}
.search-userandmail .selected-option.custom-dropdown-user-new {
  width: 361px;
}
.ReactModal__Content .custom-dropdown-new {
  max-height: fit-content !important;
  padding-right: 15px !important;
  /* position: sticky; */
  /* margin-top: 10px; */
}
.custom-dropdown-user-new-outer {
  position: relative;
}
.custom-dropdown-new .client-list{
  max-height: 100%;
}
.custom-dropdown1 input.search-bar{
  margin-top: 15px !important;
  width: 100% !important;
  max-width: 100% !important;
}
.custom-dropdown-new input.search-bar,
.custom-dropdown1 input.search-bar {
  width: 100%;
  border-radius: 5px;
  height: 40px;
  padding: 11px 18px !important;
  background-color: rgba(234, 235, 236, 0.70) !important;
  outline: none;
  margin-bottom: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../images/icons/search-icon-dd.svg) no-repeat;
  background-position: left 18px center;
  cursor: pointer;
  padding-left: 47px;
  border: none;
  font-size: 15px;
  font-family: "halyard-display", sans-serif !important;
  padding-left: 45px !important;
  position: relative;
}
.custom-dropdown-new  ul.client-list li {
  white-space: break-spaces;
  word-break: break-word;
}
.custom-dropdown-user-new-outer {
  border: none !important;
  padding: 0 !important;
  background: none !important;
  height: auto!important;
  width: auto !important;
}
.form-field-client-outer .cursor span.custom-dropdown-user-new,
.custom-dropdown-user-new,
.css-13cymwt-control {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../images/singleinvoice/dropdown.svg') !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 19px) center !important;
  color: #282828;
  background: #FFFFFF;
  border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
  border-radius: 7px !important;
  width: 100%;
  height: 43px !important;
  padding-left: 16px;
  font-weight: 300;
  line-height: 23px;
  font-size: 19px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding-right: 40px;
  cursor: pointer;
  position: relative;
}
.css-13cymwt-control {
  height: auto !important;
  min-height: 44px !important;
  padding-left: 12px !important;
}
.css-t3ipsp-control{
  min-height: 44px !important;
  padding-left: 12px !important;
  outline: none;
  box-shadow: none !important;
  border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
}
.css-13cymwt-control .css-3w2yfm-ValueContainer {
  padding: 5px 0 !important ;
}
.css-1fdsijx-ValueContainer{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.css-13cymwt-control .css-1hb7zxy-IndicatorsContainer{
  display: none;
}
.showing-result .custom-dropdown-new {
  padding: 10px 5px!important;
  background-color: #fafafa !important;
  width: 55px;
}
.css-1xc3v61-indicatorContainer {
  display: none;
}
.custom-dropdown-new ul li {
  color: #282828;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 122%;
  letter-spacing: 0.815px;
  cursor: pointer;
  margin-bottom: 0px;
  padding: 10px;
  word-break: break-word;
  white-space: break-spaces;
}
.custom-dropdown-new ul li:hover {
  color: #405BFF;
}
.new-filter-modal .dropdown-list1{
  background-clip: content-box;
  padding: 0;
}
.new-filter-modal .dropdown-list1::-webkit-scrollbar {
  width: 2px;
  /* background: transparent; */
}
.new-filter-modal .dropdown-list1 .checkbox-label1,
.new-filter-modal .dropdown-list1 .selected-option1{
  padding-left: 12px !important;
  padding-right: 10px !important;
}
.button-sec-invoice {
  grid-gap: 20px;
}
.button-sec-invoice a{
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.content-outer.Invoices.vendors .outer-invoice-clinet-section {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 991px){
  .ReactModal__Content.ReactModal__Content--after-open.new-filter-modal{
    overflow: auto;
  }
  .content-outer.Invoices.vendors{
    padding-left: 20px;
    padding-right: 5px;
  }
  .content-outer.Vendor.Invoices.vendors {
    padding-left: 20px;
    padding-right: 20px;
  }
}