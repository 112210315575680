.sheet-manual-header {
  text-align: start;
}

.type-dropdown-em {
  display: flex;
  align-items: baseline;
}

.dp-main-head-em {
  padding-right: 9px;
  font-weight: 600;
  color: #454f59;
  font-size: 15px;
  line-height: 18px;
}

select.type-select-me {
  height: 43px;
  min-width: 131px;
  border-radius: 7px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  margin-left: 0.5px;
}

.type-select-me {
  /* Remove the default arrow icon */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add a custom background image for the dropdown arrow icon */
  background-image: url("../../../images/singleinvoice/dropdown.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-position: calc(100% - 9px) center !important;
  background-color: #fff !important;
  /* Add some padding to the right to make room for the icon */
}

/* Style the dropdown arrow icon for Firefox */
.type-select-me:-moz-focusring {
  outline: 1px solid transparent;
  outline-offset: -1px;
}

.sheet-manual-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: baseline;
  gap: 31px;
  width: 100%;
  white-space: nowrap;
  flex-wrap: wrap;
}

.calender-date-range-picker .rs-picker-toggle.rs-btn.rs-btn-default {
  width: max-content !important;
}

.calender-date-range-picker input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  width: 100%;
}

.calender-date-range-picker svg.rs-picker-toggle-caret.rs-icon {
  left: unset;
}

.calender-date-range-picker input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  padding-left: 15px !important;
}

.calender-date-range-picker input.rs-picker-toggle-textbox {
  padding-left: 15px !important;
}

.calender-date-range-picker .rs-stack {
  padding-right: 27px;
}

.invo-date-rangeme {
  display: flex;
}

.invo-date-range-tile {
  font-weight: 600;
  font-size: 15px;
  color: #454f59;
  line-height: 18px;
  padding-right: 11px;
}

.invo-date-rangeme {
  display: flex;
  align-items: center;
}

.calender-issue-em svg.rs-picker-toggle-caret.rs-icon {
  display: none;
}

.calender-issue-em input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  /* padding-left: 52px !important; */
  padding: 10px 20px !important;
  background: #fafafa;
}

.calender-issue-em input.rs-picker-toggle-textbox {
  padding: 10px 20px !important;
}

.calender-issue-em .rs-picker-default .rs-picker-toggle.rs-btn {
  max-width: 115px;
}

.calender-issue-em .invo-date-issue-tile {
  font-weight: 600;
  font-size: 15px;
  color: #454f59;
  line-height: 18px;
  padding-right: 11px;
}

.calender-issue-em .invo-date-issueme {
  display: flex;
  align-items: center;
}

.invo-date-issue-tile {
  font-weight: 600;
  font-size: 15px;
  color: #454f59;
  line-height: 18px;
  padding-right: 11px;
}

.invo-date-issueme {
  display: flex;
  align-items: center;
}

.date-due-manual-edm {
  display: flex;
  align-items: center;
  padding-right: 36px;
}

.calender-date-due-pic svg.rs-picker-toggle-caret.rs-icon {
  left: auto;
}

.calender-date-due-pic input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  /* padding-left: 15px !important; */
}

.calender-date-due-pic .rs-picker-toggle.rs-btn.rs-btn-default {
  width: 140px !important;
}

.calender-date-due-pic input.rs-picker-toggle-textbox {
  padding-left: 20px !important;
}

.edit-sheet-manual01 {
  background: #ffffff;
  border-radius: 15px;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
}

/* @media (max-width: 1300px) {
    .sheet-manual-header {
      flex-wrap: wrap; 
      justify-content: flex-start;
    }
  
    .sheet-manual-header > * {
      flex-basis: 50%; 
      margin-bottom: 10px;
    }
  } */
.sheet-manual-header {
  padding: 18px 0px 22px 39px;
  border-bottom: 1px solid #e8e8e8;
}

/* .sheed-sec-after-headem {
    padding: 21px 36px 29px 53px;
} */
.input-field-inof {
  display: grid;
  text-align: left;
}

.inp-sec2-with2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 21px 36px 30px 53px;
}

.inp-sec2-with2-one {
  padding-bottom: 50px;
  ;
}

.input-field-inof label {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  padding-bottom: 11px;
  color: #282828;
}

.inp-sec2-with2 input,
.inp-sec2-with2 select {
  height: 40px;
  border: 0.5px solid #b4b4b4;
  border-radius: 5px;
  padding-left: 20px;
}

.inp-sec2-with2 select {
  height: 40px;
  border: 0.5px solid #b4b4b4;
  border-radius: 5px;
}

.inp-sec2-with2 select.type-select-me {
  width: auto !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.inp-sec2-with2 .type-select-me {
  background-position-x: 97%;
}

table.invoice-table-editmanual {
  text-align: left;
  width: 100%;
}

thead.the-abantant-edittxh th {
  font-weight: 500;
  font-size: 17px;
  color: #6a7988;
  line-height: 20px;
  padding: 10px 0px;
}

table.invoice-table-editmanual tbody {
  padding-top: 16px;
}

thead.the-abantant-edittxh {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;

}

.invoice-table-editmanual {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.invoice-table-editmanual th:first-child,
.invoice-table-editmanual td:first-child {
  width: 47.5%;
  padding-left: 14px;

}

.invoice-table-editmanual th:nth-child(2),
.invoice-table-editmanual td:nth-child(2) {
  width: 17.7%;
}

.invoice-table-editmanual th:nth-child(3),
.invoice-table-editmanual td:nth-child(3) {
  width: 15%;
}

.invoice-table-editmanual th:nth-child(4),
.invoice-table-editmanual td:nth-child(4) {
  width: 12.9%;
}

.invoice-table-editmanual {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table-editmanual th,
.invoice-table-editmanual td {
  padding: 8px 22px 8px 0px;

}

table.invoice-table-editmanual tbody tr:first-child td {
  padding-top: 16px;
}

table.invoice-table-editmanual tbody tr:last-child td {
  padding-bottom: 16px;
}

.invoice-table-editmanual input,
.invoice-table-editmanual select {
  display: block;
  width: 100%;
  border-radius: 7px;
  height: 43px;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  color: #282828;
  padding-left: 15px;
  border-radius: 5px;
  border: 0.5px solid #B4B4B4;
  background-color: #FAFAFA !important;
}

.invoice-table-editmanual select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../../../images/icons/down-arrow.svg') no-repeat;
  background-position: right 8px center;
  cursor: pointer;
  padding-right: 30px;
  margin-bottom: 0;
}

button.delete-icon {
  display: block;
  width: 100%;
  /* margin-bottom: 16px; */
  height: 43px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  color: #282828;
  padding: 0;
}

td.editmanual-amount-tr {
  padding-right: 0px;
}

button.add-item-button {
  display: flex;
  width: 110px;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  background: #f5f6f8;
  border: 0.2px solid #282828;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  padding: 0px 16px 0px 14px;
  line-height: 17px;
  color: #282828;
  margin-left: 14px;
}

.detail-add-parteditmanual {
  padding: 0px 36px 45px 39px;
}

.sec-after-tab-ls-part {
  padding: 24px 36px 29px 47px;
  border-top: 1px solid #e8e8e8;
}

.sec-withother-and.add {
  text-align: left;
}

.sec-withother-and-add {
  text-align: left;
}

button.add-item-button-br {
  display: flex;
  width: 103px;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background: #f5f6f8;
  border: 0.2px solid #282828;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  padding: 0px 16px 0px 18px;
  line-height: 17px;
  color: #282828;
}

.file-add-p1-attachment {
  padding-top: 30px;
}

table.to-ino-to-special {
  text-align: left;
  padding-left: 10px;
  padding-bottom: 8px;
}

.invo-total-specialcard {
  display: flex;
  justify-content: flex-end;
}

.sec-after-tab-ls-part {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}

.to-ino-to-special th {
  padding-right: 49px;
}

.to-ino-to-special {
  border-collapse: separate;
  border-spacing: 0 15px;
}

button.largecreate-invo-btn {
  width: 278px;
  height: 44px;
  background: #6479f8;
  border: 0.2px solid #7cc273;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.largecreate-invo-btn1 {
  width: 278px;
  height: 44px;
  background: #6479f8;
  border: 0.2px solid #7cc273;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.largecreate-invo-btn:hover,
.largecreate-invo-btn1:hover {
  opacity: 0.8;
}
.largecreate-invo-btn1:hover{
  color: #fff;
}
.specialcard-outter {
  background: #f5f6f8;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
  border-radius: 15px;
  padding: 11px 40px 31px 39px;
}

th.sub-lem-tb-fisl {
  font-weight: 500;
  font-size: 19px;
  color: #6a7988;
  line-height: 23px;
}

td.sub-price-tb-fisl {
  font-weight: 300;
  font-size: 19px;
  color: #282828;
  line-height: 23px;
}

td.sub-vatpr-tb-fisl,
th.sub-vat-tb-fisl {
  font-weight: 500;
  font-size: 19px;
  color: #6a7988;
  line-height: 23px;
}

td.sub-ivvoto-price-tb-fisl,
th.sub-invoto-tb-fisl {
  font-weight: 500;
  font-size: 19px;
  color: #282828;
  line-height: 23px;
}

.other-secp1 {
  padding-top: 18px;
}

.m-edit-main {
  padding-bottom: 83px;
}

.custom-dropdown {
  padding-right: 10px;
}

.headder-outter-res-mainu {
  /* overflow-x: scroll; */
}

.react-outerme.manual-invo-pageem {
  max-width: 1300px;
  margin: 0 auto;
}

span.tooltip {
  position: absolute;
  top: 27px;
  z-index: 1006;
  display: none;
}

.tactic00i {
  position: relative;
}

.tactic00i:hover span.tooltip {
  display: block;
}

.tactic00i:hover.headder-outter-res-mainu {
  overflow-x: visible !important;
}

.tooltip {
  max-width: 179px;
  display: grid;
  flex-wrap: wrap;
  word-break: break-word;
  text-align: left;
}

.tooltip-boxmain {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1);
  border-radius: 6px;
  position: fixed;
  z-index: 999;
  margin-top: 8px;
  top: 99px;
}

.tooltip {
  padding: 19px 10px 16px 15px;
}

h2.heading1tool {
  color: #282828;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
}

.content1tool {
  color: #6a7988;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
}

.tooltip:before {
  width: 7px;
  height: 7px;
  content: "";
  position: absolute;
  background: #ffffff;
  top: -4px;
  border-left: 0.2px solid rgba(106, 121, 136, -0.9);
  border-top: 0.2px solid rgba(106, 121, 136, -0.9);
  transform: rotate(45deg);
  /* box-shadow: 0px 2px 16px rgba(75, 85, 110, 0.2); */
  box-shadow: none;
  box-shadow: -3px -3px 5px 0px rgb(75 85 110 / 4%);
}

/* new style tooltip  */
.tooltip-boxmain {
  position: absolute;
  top: -35px;
  left: 207px;
}

.sheed-sec-after-headem {
  position: relative;
}

.second-toolhead {
  padding-top: 13px;
}

/* .rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-date-menu.rs-picker-menu{
    transform: translateX(-160px) !important;
  } */
.invo-date-issue-cal input {
  background: #fafafa;
  border: 0.5px solid #b4b4b4;
  border-radius: 5px;
  width: 115px;
  height: 42px;
  color: rgba(40, 40, 40, 0.4);
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  background-image: url(../../../images/icons/calendar-icon.svg);
  background-repeat: no-repeat;
  background-position: left 12px center;
  /* background-position: calc(100% - 15px) center !important; */
  padding-left: 40px;
  cursor: no-drop;
  background-size: 20px;
}

.other-subhead1 textarea {
  border: none;
  width: 100%;
  height: 100px;
  border: 0.5px solid #b4b4b4;
  padding: 15px;
  margin-top: 10px;
  border-radius: 5px;
  outline: none;
}

label.add-item-button-end-browse {
  display: flex;
  width: 103px;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background: #f5f6f8;
  border: 0.2px solid #282828;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  padding: 0px 18px 0px 16px;
  line-height: 17px;
  color: #282828;
  cursor: pointer;
}

.input-validation-error {
  border: 1px solid #ff0000 !important;
  background-color: #f8e1e1 !important;
}

label.add-item-button {
  display: flex;
  width: 103px;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background: #f5f6f8;
  border: 0.2px solid #282828;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  padding: 0px 18px 0px 16px;
  line-height: 17px;
  color: #282828;
}

.invo-date-range-cal .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default {
  width: 100% !important;
}

.invo-date-range-cal svg.rs-picker-toggle-caret.rs-icon {
  position: absolute;
  left: unset;
  width: 20px;
  height: 20px;
  margin-top: 4px;
  fill: #405bff;
}

.custom-dropdown select.type-select-me {
  padding-left: 9px;
  padding-right: 30px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff !important;

}

.invo-date-range-cal .rs-picker-toggle.rs-btn.rs-btn-default {
  width: 100%;
}

.invo-date-range-cal input.rs-picker-toggle-textbox {
  padding-left: 15px !important;
}

.invo-date-range-cal .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-has-value.rs-picker-cleanable input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  /* padding-left: 15px !important; */
}

.invo-date-range-cal input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  /* padding-left: 15px !important; */

}

button.del-icon-main-sel {
  background: transparent;
}

.custom-dropdown select.type-select-me {
  padding-left: 9px;
}

.invo-date-range-cal .rs-picker-toggle.rs-btn.rs-btn-default {
  width: 100%;
}

.invo-date-range-cal input.rs-picker-toggle-textbox {
  padding-left: 15px !important;
}

.invo-date-range-cal .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-has-value.rs-picker-cleanable input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  /* padding-left: 15px !important; */
}

.invo-date-range-cal input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
  /* padding-left: 15px !important; */

}
.sheet-manual-header .custoom-icon-calender.custoom-icon-calender-all input.rs-picker-toggle-textbox {
  padding-right: 10px !important;
}
.custoom-icon-calender.custoom-icon-calender-all span.rs-picker-toggle-clean.rs-btn-close svg.rs-icon {
  display: none;
}
button.del-icon-main-sel {
  background: transparent;
}

.style-portion-browsetext {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
}

.upload-file-name-br {
  display: flex;
    flex-direction: column;
    grid-gap: 5px;
}

.uploaded-file-item {
  display: flex;
  grid-gap: 5px;
}

select.type-select-me {
  padding-left: 20px;
}

.type-dropdown-em .selected-option.custom-dropdown-user-new {
  min-width: 200px;
}
.admin-outer.Invoices .s1-containauto {
  padding: 0 !important;
}
.inline-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-right: 0 !important;
}
.inline-section .type-dropdown-em,
.inline-section .custom-dropdown{
  width: 100%;
}
.inline-section .custom-dropdown{
  padding-right: 0 !important;
}
.input-field-inof.inline-section .type-dropdown-em {
  flex-direction: column;
  grid-gap: 0;
}
.input-field-inof.inline-section label{
  display: block;
  
}
.no-acccount-link {
  color: red;
}
@media screen and (max-width: 670px) {
  table.invoice-table-editmanual {
    width: 100vh;
  }
  .inline-section{
    grid-template-columns: 1fr;
  }


}
@media screen and (max-width: 500px) {
  .largecreate-invo-btn1,
  .specialcard-outter{
    width: 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1300px){
  .inp-sec2-with2,
  .inp-sec2-with2-one{
    grid-template-columns: 1fr !important;
  }
}
@media screen and (min-width: 991px) {
.content-outer.Client.Invoices.clients .new-top-flter {
  margin-top: 30px;
}
}
@media screen and (max-width: 991px) {
  .sec-after-tab-ls-part {
    display: block;
  }
  .admin-outer.Invoices .s1-containauto {
    padding: 0 0 !important;
}
.type-dropdown-em{
  grid-gap: 5px;
}
.content-outer.Invoices .fs-head-autoinvo,
.content-outer.Invoices .edit-sheet-manual01 {
  margin-right: 15px !important;
}
.admin-outer.Invoices .m-edit-main{
  padding-right: 0 !important;
}
  .sheet-manual-header {
    padding: 18px 0px 22px 19px;
  }

  .sheet-manual-header {
    gap: 21px;
  }

  .inp-sec2-with2 {
    display: grid;
    grid-template-columns: 1fr;
    padding: 21px 20px 22px 20px;
  }

  .inp-sec2-with2-one {
    padding-bottom: 50px;
  }

  .m-edit-main {
    padding-right: 12px;
  }

  .detail-add-parteditmanual {
    padding: 0px 15px 45px 20px;
    overflow: auto;
    max-width: 1000000px;
  }

  .sec-after-tab-ls-part {
    padding: 24px 15px 29px 15px;
  }

  .specialcard-outter {
    padding: 11px 15px 31px 15px;
  }

  .file-add-p1-attachment {
    padding: 30px 0px;
  }
}