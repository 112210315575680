.recent-invoice-inner {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 27px 32px;
    margin-top: 24px;
}

.recent-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #454F59;
    text-align: left;
    margin-bottom: 21px;
}

.p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 11px 0px;
    color: #343a40;
    background: #F5F6F8;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #6A7988;
}

.p-datatable .p-datatable-thead>tr>th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 22px;
}

.p-datatable .p-datatable-thead>tr>th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 22px;
    ;
}

.p-datatable-table {
    border-spacing: 0;
    width: 100%;
}

tbody.p-datatable-tbody>tr td:first-child {
    padding-left: 22px;
    ;
}

tbody.p-datatable-tbody>tr td:last-child {
    padding-right: 22px;
    ;
}

tbody.p-datatable-tbody>tr td {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #282828;
    padding-top: 19px;
    padding-bottom: 22px;
    border-bottom: 0.5px solid #D8DDEE;
}

.member-name {
    display: flex;
    align-items: center;
}

.member-name img {
    margin-right: 15px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    object-fit: contain;
}

.p-datatable .p-datatable-thead>tr>th span.p-column-title {
    margin-right: 6px;
}

tbody.p-datatable-tbody>tr td:nth-child(5) {
    font-weight: 500;
}

a.cta-link {
    background: #6479F8;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: inline-flex;
    letter-spacing: 1px;
    padding: 7px 15px;
    color: #FFFFFF;
}

span.payment-status.pending {
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #E39C4F;
    background: #FDDFB5;
    border: 0.2px solid #695437;
    border-radius: 100px;
    padding: 7px 15px;
}

span.payment-status.manual,
span.payment-status.sucessfully-processed {
    padding: 7px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #70AD69;
    background: #D5F3D8;
    border: 0.2px solid #516D4D;
    border-radius: 100px;
}

span.payment-status.payment-processeing {
    padding: 7px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #01a6ff;
    background: #9fe4fb;
    border: 0.2px solid #00047e;
    border-radius: 100px;
}

span.payment-status.overdue {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #A74E4E;
    background: #FFBBBB;
    border: 0.2px solid #9A5757;
    border-radius: 100px;
    padding: 7px 15px;
}

.recent-invoice-inner {
    overflow: scroll;
}

/* icon remove in table  */
span.p-sortable-column-icon {
    background-image: url(../../images/Notification-images/table-arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    /* background-position-y: 7px; */

}

svg.p-icon g {
    opacity: 0;
}

.member-name:hover span {
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 3px; */
    background-color: #fff;
}

::-webkit-scrollbar {
    height: 4px;
}

/* CSS for DataTable pagination */
.recent-invoices-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
}

.recent-invoices-pagination .p-paginator-current {
    color: #405BFF;
    font-weight: bold;
    margin: 0 0.5rem;
}

.recent-invoices-pagination .p-paginator-pages .p-paginator-page,
.recent-invoices-pagination .p-paginator-pages .p-paginator-element {
    color: #405BFF;
    border-radius: 5px;
    border: 0.8px solid #6A7988;
    background-color: transparent;
    padding: 5px;
    margin: 0 5.5px;
    cursor: pointer;
    min-width: 29px;
    height: 31px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.recent-invoices-pagination .p-paginator-pages .p-paginator-page:hover,
.recent-invoices-pagination .p-paginator-pages .p-paginator-element:hover {
    background-color: #405BFF;
    color: #fff;
}

.recent-invoices-pagination .p-paginator-pages .p-paginator-page.p-highlight,
.recent-invoices-pagination .p-paginator-pages .p-paginator-element.p-highlight {
    background-color: #405BFF;
    color: #fff;
}

.recent-invoices-pagination .p-paginator-pages .p-paginator-page.p-disabled,
.recent-invoices-pagination .p-paginator-pages .p-paginator-element.p-disabled {
    opacity: 0.5;
    cursor: default;
}

.recent-invoices-pagination .p-paginator-pages .p-paginator-page.p-ellipsis,
.recent-invoices-pagination .p-paginator-pages .p-paginator-element.p-ellipsis {
    cursor: default;
}

.recent-invoices-pagination .p-paginator-pages .p-paginator-page:focus,
.recent-invoices-pagination .p-paginator-pages .p-paginator-element:focus {
    outline: none;
}

.recent-invoices-pagination .p-paginator-pages .p-paginator-page.p-ellipsis::before,
.recent-invoices-pagination .p-paginator-pages .p-paginator-element.p-ellipsis::before {
    content: "...";
    display: inline-block;
    margin-right: 0.25rem;
    color: #405BFF;
}


@media screen and (max-width: 991px) {
    .recent-invoice-inner {
        width: max-content;


    }

    .recent-invoices {
        overflow: scroll;
    }

    .p-datatable .p-datatable-thead>tr>th {
        width: 163px !important;

    }

    .recent-invoices {
        margin-bottom: 47px;

    }

    tbody.p-datatable-tbody>tr:last-child td {
        border-bottom: none;
    }

    .recent-invoice-inner {
        padding: 27px 16px;
    }
}

@media screen and (max-width: 1300px) {

    .recent-invoices {
        overflow: scroll;
    }
}

@media only screen and (max-width: 1301px) and (min-width: 992px) {
    .p-datatable .p-datatable-thead>tr>th {
        width: 200px;

    }
}