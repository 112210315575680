.security-outer {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 31px 15px 50px 15px;
    margin-top: 39px;
    text-align: left;
    border: 0.2px solid #E8E8EA;
    margin-bottom: 62px;
}

.security-inner {
    border-bottom: 0.2px solid #E8E8EA;
    padding-bottom: 43px;
    margin-bottom: 40px;
    padding-left: 45px;
    padding-right: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 22px;
    flex-direction: row;
}

.security-inner:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.security-inner-title {
    display: grid;
    grid-gap: 5px;
    width: 100%;
}

.security-inner-title span.inner-title {
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 0.2px;
}

.security-inner-title span.inner-title-description {
    color: #282828;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    letter-spacing: 0.5px;
}

.button-sec-account a {
    min-width: 149px;
    height: 44px;
    border-radius: 6px;
    background: #6479F8;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: max-content;
    padding: 10px;
}

.button-sec-account button {
    color: #FE6161;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    background: transparent;
    width: 149px;
}

.two-fa-modal-outer .twoFAContainer {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: auto !important;
    position: relative;
    padding-top: 7px !important;
}

.two-fa-modal-outer .twoFAContainer .qr-des p,
.two-fa-modal-outer .twoFAContainer .qr-bottom-sec p {
    color: #78828D;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 23.18px;
    margin-bottom: 16px;
}

.two-fa-modal-outer .twoFAContainer .qr-bottom-sec p {
    margin-bottom: 30px;
}

.userAccount-modal-two-fa {
    max-width: fit-content !important;
    max-height: fit-content !important;
    height: fit-content !important;
}

.two-fa-error {
    background-color: transparent !important;
    position: absolute;
    bottom: -25px;
    margin-bottom: 0 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal.userAccount-modal.twofa-modal {
    max-height: 100% !important;
}

@media only screen and (max-width: 480px) {
    .security-inner {
        padding-left: 11px;
        padding-right: 11px;
        flex-direction: column;
        justify-content: left;
    }

    .button-sec-account button {
        text-align: left;
    }

    .button-sec-account {
        width: 100%;
    }
}

@media only screen and (max-width: 991px) and (min-width: 481px) {

    .ReactModal__Content.ReactModal__Content--after-open.client-modal.userAccount-modal.twofa-modal,
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.userAccount-modal.userAccount-modal-two-fa {
        transform: translate(0%, -50%) !important;
        max-height: 60% !important;
    }
}
@media only screen and (max-width: 350px) {
    .security-inner.noti-enable {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
    }
    .security-inner.noti-enable span.custom-switch-description{
        width: 100%;
    }
    .toggle-spinner{
        width: 100%;
    }
    .security-inner.noti-enable .custom-dropdown{
        width: 100%;
    }
    .security-inner.noti-enable .custom-dropdown-user-new-outer.custom-dropdown-select{
        width: 100% !important;
    }
}
@media only screen and (max-width: 991px) {
    .tab-outer-tab-account {
        border-radius: 15px;
        background: #FFF;
        box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
        padding: 5px 0px 50px 0px;
        margin-top: 39px;
        text-align: left;
        border: 0.2px solid #E8E8EA;
        margin-bottom: 62px;
        margin-left: 12px;
        margin-right: 12px;
    }
    .security-inner.noti-enable.noti-head-noti{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .security-inner.noti-enable .custom-dropdown-user-new-outer.custom-dropdown-select{
        width: 200px;
    }

    .security-outer {
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        padding-top: 0;
        margin-top: 51px;
        text-align: left;
        border: none;
        margin-bottom: 0;
    }

    .two-fa-modal-outer .twoFAContainer {
        margin-bottom: 0 !important;
    }

    .two-fa-modal-outer .qr-title h2 {
        margin-top: 15px !important;
        margin-bottom: 17px;
    }
}

@media only screen and (min-width: 650px) {
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.userAccount-modal.twofa-modal {
        width: 627px !important;
        max-width: 627px !important;
    }
}

@media only screen and (max-width: 650px) {
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.userAccount-modal.twofa-modal {
        width: 90% !important;
        max-width: 100% !important;
    }
}

@media only screen and (min-width: 992px) {
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.userAccount-modal.twofa-modal {
        transform: translate(-26%, -50%) !important;
    }
}