.user-outer-timeoff .recent-invoice-inner {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
    padding: 27px 32px;
    margin-top: 24px;
}

.user-outer-timeoff .recent-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #454F59;
    text-align: left;
    margin-bottom: 21px;
}

.user-outer-timeoff .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 12px 0px;
    color: #343a40;
    background: #F5F6F8;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #6A7988;
    padding-top: 11px;
    padding-right: 20px;
}

.user-outer-timeoff .p-datatable .p-datatable-thead>tr>th:first-child {
    border-top-left-radius: 10px !important;
    padding-left: 15px;
    border-bottom-left-radius: 10px;
}

.user-outer-timeoff .p-datatable .p-datatable-thead>tr>th:last-child {
    border-top-right-radius: 10px !important;
    padding-right: 22px;
    border-bottom-right-radius: 10px !important;
}

.user-outer-timeoff .p-datatable-table {
    border-spacing: 0;
    width: 100%;
}

.user-outer-timeoff tbody.p-datatable-tbody>tr td:first-child {
    padding-left: 15px;
}

.user-outer-timeoff tbody.p-datatable-tbody>tr td:last-child {
    padding-right: 22px;
    ;
}

.user-outer-timeoff tbody.p-datatable-tbody>tr td {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #282828;
    padding-top: 20px;
    padding-bottom: 22px;
    border-bottom: 0.5px solid #D8DDEE;
}

.user-outer-timeoff tbody.p-datatable-tbody tr:first-child td {
    padding-top: 27px !important;
}

.user-outer-timeoff .card-outer {
    background: #fff;
    padding: 20px 38px 0 32px;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
    border-radius: 15px;
}

.user-outer-timeoff .member-name {
    display: flex;
    align-items: center;
}

.user-outer-timeoff .member-name img {
    margin-right: 15px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
}

.p-datatable .p-datatable-thead>tr>th span.p-column-title {
    margin-right: 6px;
}

.user-outer-timeoff a.cta-link {
    background: #6479F8;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: inline-flex;
    letter-spacing: 1px;
    padding: 6px 15px 7px 14px;
    color: #FFFFFF;
}

.user-outer-timeoff span.payment-status.pending {
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #E39C4F;
    background: #FDDFB5;
    border: 0.2px solid #695437;
    border-radius: 100px;
    padding: 6px 10px 8px 11px;
}

.user-outer-timeoff span.payment-status.manual,
.user-outer-timeoff span.payment-status.sucessfully-processed {
    padding: 7px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 122%;
    letter-spacing: 0.4px;
    color: #7CC273;
    background: #D5F3D8;
    border: 0.2px solid #516D4D;
    border-radius: 100px;
}

.user-outer-timeoff span.payment-status.overdue {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #A74E4E;
    background: #FFBBBB;
    border: 0.2px solid #9A5757;
    border-radius: 100px;
    padding: 6px 10px 8px 11px;
}

.user-outer-timeoff .recent-invoice-inner {
    overflow: scroll;
}
.form-field-client-btns.timeoff-btn {
    padding-bottom: 0;
}
/* 
.user-outer-timeoff .member-name:hover span {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    padding: 3px;
} */
.time0ff-membername {
    display: flex;
    align-items: center;
    min-width: 170px;
}
li.previous a{
    border: none !important;
}
.user-outer-timeoff .p-datatable-wrapper {
    max-width: 1000000px;
    width: 100%;
    overflow: auto;
    white-space: pre;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 15px;
}

.user-outer-timeoff .card-outer-user h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26.84px;
    color: #454F59;
    text-align: left;
    margin-bottom: 21px;
}

.modal-close-invite.outter-modal-main {
    display: flex;
    justify-content: flex-end;
    background: #fff;
    /* margin-left: -20px;
    margin-right: -20px; */
    z-index: 99999;
    top: -20px;
}

.modal-invite-head h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #282828;
}

.the-modal-inuser label {
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    color: #6A7988;
}

.the-modal-inuser input,
.the-modal-inuser select {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 7px;
    width: 100%;
    height: 43px;
    color: #282828;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    padding-left: 15px;
    margin-top: 10px;

}

.the-modal-inuser select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../images/icons/down-arrow.svg') no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    padding-right: 30px;
}

.type-of-acc {
    margin-top: 15px;
    display: block;
}

.add-icon-main-text {
    color: #282828;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    padding-left: 11px;

}

.add-email-sec-modal {
    display: flex;
    padding-top: 15px;
    padding-bottom: 35px;
    align-items: center;

}

button.submit-client.save-sprint {
    min-width: 154px;
}

.the-modal-inuser {
    padding-top: 24px;
}

.invite-mail-popup-btn {
    display: flex;
    justify-content: flex-end;
}

a.btn-forpop-initem {
    padding: 13px 18px 14px 14px;
}

.form-field-client-btns.timeoff-btn a {
    margin-top: 13px !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review.time-off-review-new .form-field-client {
    display: flex;
    /* justify-content: space-between; */
    grid-gap: 30px;
    flex-wrap: row;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review.time-off-review-new label.form-label{
    width: 40%;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review.time-off-review-new .timeoff-user-inner{
    width: 52%;
}
.time-off-user-name{
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.form-field-client.remark {
    grid-gap: 10px !important;
}
.form-field-client-outer > div:nth-last-child(4){
    /* border: none !important; */
}
.form-field-client-btns.timeoff-btn button.delete-client{
    border: 1px solid #282828 !important;
}
.form-field-client-btns.timeoff-btn.user-timeoff {
    justify-content: center;
    grid-template-columns: 1fr;
}
.form-field-client-btns.timeoff-btn.user-timeoff button.delete-client{
    border: none !important;
}
@media screen and (max-width: 991px) {
    .user-outer-timeoff .recent-invoice-inner {
        width: max-content;


    }
    .content-outer.Invoices {
        padding-right: 0;
    }

    .user-outer-timeoff .recent-invoices {
        overflow: scroll;
    }

    .user-outer-timeoff .p-datatable .p-datatable-thead>tr>th {
        width: 200px !important;

    }
}
@media screen and (min-width: 1200px){
    .timer-left .project-name .dropdown {
        min-width: 600px;
    }
}

@media screen and (max-width: 1300px) {
    .user-outer-timeoff .p-datatable .p-datatable-thead>tr>th {
        width: 200px !important;

    }

    .user-outer-timeoff .recent-invoices {
        overflow: scroll;
    }
}

.user-outer-timeoff tbody.p-datatable-tbody {
    background: #fff;
}

.user-outer-timeoff .button-sec-invoice a {
    background: #6479F8;
    border-radius: 6px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 44px;
}

.user-outer-timeoff .button-sec-invoice {
    display: flex;
    padding-top: 0;
    margin-bottom: 0px;
}

.user-outer-timeoff .button-sec-invoice a:hover {
    text-decoration: none;
    opacity: 0.8;
}

.user-outer-timeoff .button-sec-invoice a:first-child {
    /* margin-right: 16px; */
    /* padding: 13px 18px 14px 18px; */
}

.user-outer-timeoff .button-sec-invoice a:last-child {
    /* padding: 15px 14px 14px 14px; */
}

.user-outer-timeoff span.icon-invoice img {
    margin-right: 8.5px;
}

.user-outer-timeoff .button-sec-invoice a:first-child span.icon-invoice img {
    /* width: 13.69px !important;
    height: 17.34px !important; */
}

.user-outer-timeoff .button-sec-invoice a:last-child span.icon-invoice img {
    /* width: 23px !important;
    height: 15px !important; */
}

.user-outer-timeoff .button-sec-invoice a:nth-child(2) span.icon-invoice img {
    margin-right: 12px;
}

.user-outer-timeoff .calendar-icon {
    position: relative;
    display: inline-block;
}

/* Adjust the icon size as needed */
.user-outer-timeoff .calendar-icon img {
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.user-outer-timeoff .invoice-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 0px 70px;
}

.user-outer-timeoff .filter-inner {
    display: inline-grid;
    text-align: left;
}

.user-outer-timeoff .filter-inner.showing-result {
    display: inline-flex;
    white-space: nowrap;
    margin-top: 0px;
    position: unset;
    align-items: center;
}

.user-outer-timeoff .invoice-filter-inner {
    display: flex;
    grid-gap: 14px;
    width: 1060px;
    overflow: auto;
}

.user-outer-timeoff .filter-invoice-right {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
}

.user-outer-timeoff .filter-inner.showing-result label {
    margin-bottom: 0;
}

.user-outer-timeoff td.amount-bold {
    font-weight: 500 !important;
}

.user-outer-timeoff .invoice-filter label {
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #282828;
    margin-bottom: 7px;
}

.user-outer-timeoff .filter-inner input,
.user-outer-timeoff .filter-inner select {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    padding: 10px 16px 12px 16px;
    color: #78828D;
    font-size: 17px;
}

.user-outer-timeoff .filter-inner.invoice-id input {
    min-width: 110px;
}

.user-outer-timeoff .filter-inner.invoice-status select {
    min-width: 130px;
}

.user-outer-timeoff .filter-inner.due-date input {
    min-width: 121px;
}

.user-outer-timeoff .filter-inner.payment-status select {
    min-width: 136px;
}

.user-outer-timeoff .filter-inner select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.user-outer-timeoff .filter-inner.invoice-type select {
    min-width: 111px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.user-outer-timeoff .filter-inner.user input {
    min-width: 198px;
}

.user-outer-timeoff .filter-inner.showing-result select {
    min-width: 44px;
    height: 27px;
    padding: 1px 8px 4px 8px;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    color: #282828;
}

.user-outer-timeoff .filter-inner.showing-result label:first-child {
    padding-right: 10px;
}

.user-outer-timeoff .filter-inner.showing-result label:last-child {
    padding-left: 10px;
}

.user-outer-timeoff .invoice-top {
    padding-bottom: 22px;
}

.user-outer-timeoff .filter-inner.due-date input {
    font-size: 13px;
    padding: 11px 9px 13px 12px;
    color: #858585;
}

.user-outer-timeoff .filter-inner select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../images//icons/down-arrow.svg') no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    padding-right: 30px;
}

.user-outer-timeoff .filter-inner.showing-result select {
    background: url('../../images//icons/down-arrow-gray.png') no-repeat;
    background-position: right 8px center;
    background-color: #FAFAFA;
}

/* For Firefox */
.user-outer-timeoff .filter-inner.due-date::-ms-expand {
    display: none;
}

.user-outer-timeoff .filter-inner.invoice-type select {
    width: 111px;
}

.user-outer-timeoff {
    padding-bottom: 55px;
}

.user-outer-timeoff span.payment-status.active {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #7CC273;
    background: #D5F3D8;
    border: 0.2px solid #7CC273;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
}

.user-outer-timeoff span.payment-status.inactive {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    color: #96A8BA;
    background: #EAEAEA;
    border: 0.2px solid #6A7988;
    border-radius: 100px;
    width: 67px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
}

.user-outer-timeoff .invoice-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 21px;
}

.user-outer-timeoff .search-container input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../images/icons/search-icon.svg') no-repeat;
    background-position: left 15px center;
    cursor: pointer;
    padding-left: 58px;
    background-color: #EAEBEC;
    border-radius: 10px;
    width: 318px;
    height: 45px;
    border: none;
    font-weight: 300;
    font-size: 19px;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    color: #6A7988;
    padding-right: 15px;
}

.user-outer-timeoff tbody.p-datatable-tbody>tr:last-child td {
    border-bottom: none;
}

.user-outer-timeoff .datatable-team-maintb {
    margin-top: 23px;
    padding-top: 0;
}

.showing-count {
    display: flex;
    justify-content: flex-end;
}

.overview-top-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.range-timeoff {
    display: flex;
    grid-gap: 13px;
}

.range-timeoff-inner .range-title,
.range-timeoff-user label {
    text-align: left;
    color: #282828;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
}

.range-timeoff-user select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../images/icons/down-arrow.svg') no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    padding-right: 30px;
    background-color: #FFFFFF !important;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    padding: 10px 16px 12px 16px;
    color: #78828D;
    font-size: 17px;
    min-width: 182px;
}

.range-timeoff-user {
    display: flex;
    flex-direction: column;
}

.range-timeoff-inner input.rs-picker-toggle-textbox {
    padding-left: 15px !important;
    padding-right: 40px !important;
}

.range-timeoff-inner input.rs-picker-toggle-textbox::placeholder,
.range-timeoff-inner input.rs-picker-toggle-textbox {
    color: #282828;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    text-transform: lowercase;
    border-radius: 10px !important;
}

.range-timeoff-inner .rs-picker-toggle.rs-btn.rs-btn-default {
    border-radius: 5px !important;
    /* z-index: 0; */
}

.range-timeoff-inner .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default {
    width: 247px;
    height: 40px;
    background: #FBFBFD;
}
.custoom-icon-calender.custoom-icon-calender-all .project-calender input.rs-picker-toggle-textbox .content-outer.Invoices{
    padding-left: 42px !important;
}
.custoom-icon-calender.custoom-icon-calender-all input.rs-picker-toggle-textbox,
.custoom-icon-calender.custoom-icon-calender-all input,
.content-outer.Projects.clients .custoom-icon-calender.custoom-icon-calender-all input,
.content-outer.Time.Off .custoom-icon-calender.custoom-icon-calender-all input,
.content-outer.Invoices .custoom-icon-calender.custoom-icon-calender-all input,
.custoom-icon-calender.custoom-icon-calender-all .rs-stack-item input.rs-picker-toggle-textbox,
.form-field-client.tracker-detail-dropdown input.rs-picker-toggle-textbox,
.ReactModal__Content.ReactModal__Content--after-open.new-filter-modal .date-picker-outer input[type="text"]{
    padding-left: 40px !important;
    background: url('../../images/icons/calendar-icon.svg') !important;
    background-size: 20px !important;
    background-repeat: no-repeat !important;
    background-position: left 11px center !important;
    padding-left: 42px !important;
    background-color: #fff !important;
    text-transform: lowercase !important;
}
.custoom-icon-calender.custoom-icon-calender-all input.rs-picker-toggle-textbox::placeholder,
.custoom-icon-calender.custoom-icon-calender-all input::placeholder,
.content-outer.Projects.clients .custoom-icon-calender.custoom-icon-calender-all input::placeholder,
.content-outer.Time.Off .custoom-icon-calender.custoom-icon-calender-all input::placeholder,
.content-outer.Invoices .custoom-icon-calender.custoom-icon-calender-all input::placeholder,
.custoom-icon-calender.custoom-icon-calender-all .rs-stack-item input.rs-picker-toggle-textbox::placeholder,
.form-field-client.tracker-detail-dropdown input.rs-picker-toggle-textbox::placeholder{
    text-transform: lowercase !important;
}
.content-outer.Time.Off .overview-top-right input#date-range::placeholder,
.content-outer.Expenses .invoice-filter-inner-section.no-flter-all input#invoiceId::placeholder,
.content-outer.Projects.clients .invoice-filter-inner-section input#invoiceId::placeholder{
    text-transform: capitalize !important;
}
.date-overview-sec.date-overview-sec-rem {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.date-overview-sec.date-overview-sec-rem  .form-field-client.tracker-detail-dropdown input.rs-picker-toggle-textbox {
    padding-left: 45px !important;
}

.range-timeoff-inner svg.rs-picker-toggle-caret.rs-icon {
    left: auto;
    right: 15px !important;
}

.range-timeoff-inner .rs-picker-toggle.rs-btn.rs-btn-default {
    width: 247px;
}

.user-outer-timeoff .datatable-team-maintb td {
    text-align: left;
}

span.user-image-listingteam img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    object-fit: contain;
}

.user-outer-timeoff th.sortable-header {
    padding: 12px !important;
    color: #6A7988;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.74px;
}

.user-outer-timeoff .datatable-team-maintb td .approved {
    border-radius: 100px;
    border: 0.2px solid #516D4D;
    background: #D5F3D8;
    width: 84px;
    height: 31px;
    color: #70AD69;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-outer-timeoff .datatable-team-maintb td .approved,
.user-outer-timeoff .datatable-team-maintb td .rejected,
.user-outer-timeoff .datatable-team-maintb td .pending {
    border-radius: 100px;
    width: 84px;
    height: 31px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-outer-timeoff .datatable-team-maintb td .approved {
    border: 0.2px solid #516D4D;
    background: #D5F3D8;
    color: #70AD69;
}

.user-outer-timeoff .datatable-team-maintb td .rejected {
    border: 0.2px solid #9A5757;
    background: #FBB;
    color: #A74E4E;
}

.user-outer-timeoff .datatable-team-maintb td .pending {
    border: 0.2px solid #695437;
    background: #FDDFB5;
    color: #E39C4F;
}

.user-outer-timeoff .head-tb-of-team-member>tr>th:first-child,
.user-outer-timeoff .body-tb-of-team-member>tr td:first-child {
    padding-left: 19px !important;
}

.user-outer-timeoff .datatable-team-maintb td {
    border-color: #D8DDE1;
    padding-top: 18px;
    padding-bottom: 23px;
}

.user-outer-timeoff .datatable-team-maintb td:last-child {
    text-align: right;
    padding-right: 18px;
}

.disable-cursor {
    cursor: default;
}

.timeoff-modal .form-field-client-outer .form-field-client .form-select {
    background: url('../../images/icons/down-arrow.svg') no-repeat;
    background-position: right 18px center;
}

.modal.timeoff-modal input.rs-picker-toggle-textbox,
.modal.timeoff-modal input.rs-picker-toggle-textbox::placeholder {
    color: #282828;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 17.08px;
    letter-spacing: 0.5px;
}

.timeoff-review .form-field-client-outer .form-field-client {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 17px;
    padding-bottom: 11px;
    color: #282828;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 1px;
}

.timeoff-review .form-field-client-outer .form-field-client .form-label {
    display: flex;
    flex-direction: row;
    grid-gap: 13px;
    color: #6A7988;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.74px;
    align-items: center;
}

.timeoff-review .form-field-client-outer .form-field-client .form-label .icon-before-time {
    width: 21px;
    display: flex;
    justify-content: center;

}

.form-field-client.remark {
    flex-direction: column;
    display: flex !important;
}

.remark-note {
    padding-bottom: 20px;
}

.form-field-client.end-time {
    border: none !important;
}

.form-field-client-btns.timeoff-btn a {
    width: 100%;
    max-width: 100%;
}

.form-field-client-btns.delete-timeoff button.delete-client {
    background: transparent !important;
    border: none;
}

.form-field-client-btns.delete-timeoff {
    display: grid !important;
    justify-content: center;
    grid-template-columns: 1fr;
}

.icon-before-time img {
    /* object-fit: none; */
}

.timeoff-user-inner {
    display: flex;
    align-items: center;
}

.range-timeoff-inner input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
    padding-left: 18px !important;
}

.range-timeoff-user select {
    width: 247px;
}

.showing-count.showing-count-timeoff .filter-inner.showing-result.show-result-team-fill {
    position: unset;
    margin-right: 20px;
    flex-direction: row;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review.time-off-review-new .modal-inner-top-sec{
     position: sticky;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review.time-off-review-new .modal-client-head h2{
    margin-bottom: 0;
    padding-bottom: 30px;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review.time-off-review-new {
    overflow: auto !important;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review.time-off-review-new .form-field-client-outer {
    margin-top: 20px;
}
.manage.manage-time-off{
    margin-top: 21px;
}
.manage.manage-time-off a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #6479F8;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    grid-gap: 9px;
}
.content-outer.Time.Off .invoice-top {
    padding-top: 21px !important;
}
.content-outer.Time.Off .search-userandmail {
    padding: 0;
}
.content-outer.Time.Off.Settings {
    padding-left: 0;
    padding-right: 0;
}
.content-outer.Time.Off.Settings .text-arrow-outer {
    margin-top: 0;
    margin-left: 0;
}
@media only screen and (max-width: 991px) {
    .ReactModal__Content.ReactModal__Content--after-open.delete-modal {
        /* transform: translate(-50%, -50%) !important; */
    }
}

@media only screen and (min-width: 500px) {
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.time-off-review {
        width: 461px !important;
        max-width: 461px !important;
    }
}

@media only screen and (max-width: 667px) {
    .user-outer-timeoff .invoice-top {
        flex-wrap: wrap;
        padding-right: 0px;
    }
    .content-outer.Equipment .button-sec-invoice{       
        /* width: auto !important; */
    }
    .user-outer-timeoff .button-sec-invoice {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .user-outer-timeoff .button-sec-invoice a,
    .content-outer.Equipment .button-sec-invoice a {
        width: 100%;
    }
    .content-outer.Time.Off a.btn-forpop-initem,
    .content-outer.Equipment a.btn-forpop-initem{
        width: 48% !important;
    }
    .user-outer-timeoff .button-sec-invoice a:first-child {
        margin-right: 0 !important;
    }
    .content-outer.Time.Off .search-userandmail,
    .content-outer.Equipment .search-userandmail{
        padding: 0 !important;
        width: 48%;
    }
    .user-outer-timeoff .button-sec-invoice {
        margin-bottom: 26px;
    }

    .user-outer-timeoff .search-sec,
    .user-outer-timeoff .search-container input {
        width: 100%;
    }

    .user-outer-timeoff .invoice-top {
        padding-top: 28px;
        padding-bottom: 27px;
    }

    .user-outer-timeoff .admin-outer.list .content-outer {
        padding-right: 0;
    }

    .user-outer-timeoff .p-datatable-wrapper {
        max-width: 100% !important;
        overflow: initial;
    }

    .overview-top-right {
        flex-direction: column;
        grid-gap: 20px;
    }

    .range-timeoff {
        /* flex-direction: column; */
        flex-wrap: wrap;
        width: 100%;
        text-align: left;
    }

    .user-outer-timeoff .rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-has-value.rs-picker-cleanable {
        width: 100%;
    }

    .user-outer-timeoff .range-timeoff-inner .rs-picker-toggle.rs-btn.rs-btn-default {
        width: 100%;
    }

    .showing-count {
        width: 100%;
    }
}

/* .form-field-client-outer .form-field-client .form-select{

} */
@media only screen and (max-width: 991px) {

    /* .user-outer-timeoff .button-sec-invoice{
        display: none;
    } */
    .user-outer-timeoff .overview-top-right {
        flex-wrap: wrap;

    }

    .user-outer-timeoff .overview-top-right .showing-count {
        width: 100%;
        margin-top: 20px;
    }

    .user-outer-timeoff {
        padding-bottom: 48px;
    }

    .user-outer-timeoff .invoice-filter-inner {
        padding-bottom: 10px;
    }

    .user-outer-timeoff .filter-inner.showing-result {
        margin-top: 10px;
    }

    .user-outer-timeoff .admin-outer.invoices .content-outer {
        background: #FBFBFD;
    }

    .user-outer-timeoff .invoice-top {
        padding-top: 20px;
    }

    .admin-outer.list .content-outer {
        padding-right: 0;
    }

    .user-outer-timeoff .card-outer1 {
        overflow: auto;
        padding-right: 15px;
    }

    .user-outer-timeoff .card-outer {
        width: max-content;
        overflow: scroll;
    }

    .user-outer-timeoff .p-datatable .p-datatable-thead>tr>th {
        width: auto !important;
    }

    .user-outer-timeoff .card-outer {
        padding-right: 32px;
    }
}

@media only screen and (max-width: 1220px) and (min-width: 992px) {
    .user-outer-timeoff .overview-top-right {
        flex-wrap: wrap;
        grid-gap: 0 30px;

    }

    .user-outer-timeoff .showing-count {
        margin-top: 30px;
    }
}