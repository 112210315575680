.overview-outer-time .overview-top .overview-top-inner {
    display: flex;
    justify-content: space-between;
    padding: 22px 35px 28px 20px;
    align-items: center;
}

.overview-outer-time {
    padding: 0 44px;
}

.overview-outer-time .overview-top .overview-top-left a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background: #6479F8;
    border-radius: 6px;
    justify-content: center;
}

.overview-outer-time .overview-top .overview-top-left a:first-child {
    /* width: 104px; */
    padding: 0 15px;
    height: 44px;
    grid-gap: 8.5px;
}

.overview-outer-time .overview-top .overview-top-left a:last-child {
    /* width: 101px; */
    padding: 0 15px;
    height: 44px;
    grid-gap: 8.5px;
}

.overview-outer-time .overview-top .overview-top-left {
    display: flex;
    grid-gap: 20px;
}

.overview-outer-time .overview-top .filter-inner.showing-result label {
    font-weight: 300;
    font-size: 12px;
    line-height: 14.64px;
    letter-spacing: 0.5px;
    color: #282828;
}

.overview-outer-time .overview-top .filter-inner.showing-result {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    flex-direction: row;
}

.overview-outer-time .overview-top .filter-inner.showing-result select {
    min-width: 44px;
    height: 27px;
    background-color: #FAFAFA;
    border: 0.5px solid #B4B4B4;
    border-radius: 5px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13.42px;
    letter-spacing: 0.5px;
    color: #282828;
    padding-left: 10px;
    background: url(../../../images/icons/down-arrow-gray.png) no-repeat;
    background-position: right 8px center;
    padding-right: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

.overview-outer-time .filter-sec-overview .filter-inner input,
.overview-outer-time .filter-sec-overview .filter-inner select,
.selected-options1 {
    background-color: #FFFFFF !important;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    padding: 0px 16px 0px 16px;
    color: #78828D;
    font-size: 17px;
    max-width: 188px;
}

.overview-outer-time .filter-sec-overview input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
    width: 121px !important;
    font-weight: 300;
    font-size: 13px;
    line-height: 15.86px;
    letter-spacing: 0.5px;
    color: #858585;
    border: 0;
}

.overview-outer-time svg.rs-picker-toggle-caret.rs-icon {
    left: auto !important;
}

.overview-outer-time input.rs-picker-toggle-textbox.rs-picker-toggle-read-only {
    padding-left: 11px !important;
    padding-right: 50px !important;
}

.overview-outer-time .filter-sec-overview .rs-picker-default .rs-picker-toggle.rs-btn {
    width: 121px !important;
    border-radius: 5px;
}

.overview-outer-time .filter-sec-overview .filter-inner select,
.selected-options1 {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../../images/icons/down-arrow.svg') no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    padding-right: 30px;
}

.filter-sec-overview .invoice-filter-inner {
    display: flex;
    flex-direction: row;
    grid-gap: 18px;
    align-items: flex-end;
    justify-content: space-between;
}

.filter-sec-overview .invoice-filter-inner .filter-inner {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    grid-gap: 7px;
}

.filter-sec-overview input.rs-picker-toggle-textbox {
    padding-left: 11px !important;
    padding-right: 40px !important;
    font-weight: 300;
    font-size: 13px !important;
    line-height: 15.86px;
    letter-spacing: 0.5px;
    color: #858585 !important;
    text-transform: lowercase;
}

.tab-inner-time .graph-piechart {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: 18px;
}

.tab-inner-time .overview-graph-time {
    width: 66%;
}

.piechart {
    width: 32%;
}

.filter-sec-overview {
    max-width: 100000px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.filter-sec-overview {
    margin-bottom: 22px;
}

/* Your stylesheet */
.tracker-date-picker .rdrDateRangeWrapper input {
    display: none;
}

.overview-outer-time .filter-sec-overview .filter-inner input,
.overview-outer-time .filter-sec-overview .filter-inner select {
    /* width: 182px; */
}

.filter-sec-overview input.rs-picker-toggle-textbox {
    width: 121px !important;
}

.filter-sec-overview .invoice-filter-inner .filter-inner label {
    color: #282828;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 15.86px;
    letter-spacing: 0.5px;
}

.overview-outer-time .filter-sec-overview .filter-inner select {
    max-width: 180px;
    min-width: 180px;
}

.filter-inner.invoice-type select {
    max-width: 180px;
    min-width: 180px;
}

.overview-outer-time .filter-sec-overview .tracker-date-picker .rs-picker-toggle.rs-btn.rs-btn-default {
    width: 100% !important;
}

.overview-outer-time .filter-sec-overview .tracker-date-picker input#date-range {
    width: 100% !important;
    max-width: 100% !important;
    /* padding-right: 30px !important; */
}

.overview-outer-time .filter-sec-overview .tracker-date-picker span.rs-picker-toggle-value {
    display: none;
}

.overview-outer-time .filter-sec-overview .tracker-date-picker {
    display: inline-block;
    max-width: 215px;
    vertical-align: middle;
    width: 215px !important;
}
li.previous a {
    border: none;
}
.filter-sec-overview.active .invoice-filter-inner,
.invoice-filter-inner.active .invoice-filter-inner-section {
    /* overflow-x: clip; */
    width: 100%;
}

.filter-sec-overview.active,
.invoice-filter-inner.active,
.invoice-filter-inner.active .invoice-filter-inner-section {
    overflow: visible !important;
    display: flex;
}

.dropdown-list1 .checkbox-label1,
.dropdown-list1 .selected-option1 {
    color: #282828 !important;
    font-size: 17px !important;
    font-weight: 300;
    line-height: 20.74px !important;
    letter-spacing: 1px !important;
    border-bottom: 0.5px solid #D8DDE1;
    display: flex;
    align-items: center;
    padding: 16px 5px;
    white-space: break-spaces;
    text-align: left;
    word-break: break-word;
}

.dropdown-list1 .checkbox-label1:last-child,
.dropdown-list1 .selected-option1:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.dropdown-list1::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.dropdown-list1::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 50px;
}

/* Handle */
.dropdown-list1::-webkit-scrollbar-thumb {
    background: #96a8ba;
    background-clip: padding-box;
    border-radius: 50px;
}

th.fixed-se .p-column-title {
    padding-top: 4px !important;
    display: flex;
}

td.fixed-se input {
    margin-top: 4px !important;
    display: flex;
}

/* Handle on hover */
.dropdown-list1::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

label.checkbox-label1 input {
    margin-right: 13px !important;
}

.selected-options1 {
    display: flex;
    align-items: center;
}

.selected-option-count {
    background: #405BFF;
    color: #fff;
    padding: 3px 6px;
    border-radius: 50%;
    font-size: 10px;
    margin-left: 7px;
    font-weight: 500;
}
/* Hide the default checkbox */
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #6A7988;
    outline: none;
    cursor: pointer;
    position: relative;
  }
  th.time-tracker-top-checkbox input[type="checkbox"]:checked::before {
    content: "-";
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 1px;
    left: 9.7px;
    transform: translate(-50%, -50%);
    opacity: 1;
    background: none !important;
    font-size: 28px;
  }
  th.time-tracker-top-checkbox input[type="checkbox"]:checked{
    background: #6A7988;
    border: 1px solid #6A7988;
  }
  /* Show the checkmark when the checkbox is checked */
  input[type="checkbox"]:checked::before {
    content: ""; /* Clear default content */
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8' fill='none'%3E%3Cpath d='M1 4L3.5 6.5L9 1' stroke='white' stroke-width='1.4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center; /* Center the SVG */
    position: absolute; /* Position the pseudo-element */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1; /* Show the tick mark */
  }
  input[type="checkbox"]:checked{
    background: #405BFF;
    border: 1px solid #405BFF;
  }
  /* Hide the checkmark by default */
  input[type="checkbox"]::before {
    content: "";
    display: none;
  }
  
  /* Show the checkmark when the checkbox is checked */
  input[type="checkbox"]:checked::before {
    display: block; /* Show the checkmark */
  }
  /* .time-table th:nth-child(3) {
    padding: 0;
    padding-left: 5px;
} */
.bulk-edit button {
    border-radius: 6px;
    border: 0.2px solid #282828;
    background: #F5F6F8;
    padding: 9px 10px;
    color: #405BFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    grid-gap: 7px;
    text-transform: capitalize;
    width: 96px;
    height: 32px;
}
.bulk-edit .delete-tracker-details{
    color: #282828;
}
.bulk-edit .delete-tracker-details img{
    width: 15.867px;
    height: 17px;
}
.bulk-edit button img {
    width: 13.28px;
    height: 13.36px;
    margin-left: 0;
}
tr.member-extra .value-field {
    background: transparent;
}
@media (max-width: 991px){
    .content-outer.Time.Tracker .overview-outer-time .overview-top .overview-top-left a:last-child {
        display: none;
    }
}
@media (max-width: 768px) {
    .dropdown-list1 {
        top: auto !important;
        left: 23px !important;
        width: 90% !important;
    }

    .custom-dropdown1 {
        position: static !important;
    }

}
@media (max-width: 767px) {
    .filter-invoice-right.apply-fliter-right{
        width: 100%;
    }

    .admin-outer.Projects .invoice-outer-invoice .button-sec-invoice a:last-child{
        display: none;
    }
}
@media only screen and (min-width: 1440px) {

    .filter-sec-overview .invoice-filter-inner,
    .filter-sec-overview {
        margin-bottom: 11px;
    }
    .filter-invoice-right button{
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

}

@media only screen and (max-width: 1440px) and (min-width: 1025px) {
    .overview-outer-time .overview-top .overview-top-inner {
        padding: 22px 0px 28px 0px;
    }

    .filter-sec-overview .invoice-filter-inner {
        padding-bottom: 11px;
        /* margin-bottom: 11px; */
    }

    .overview-outer-time {
        margin: 0 40px;
        padding: 0;
    }

    .tab-outer-time ul.tabs {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width: 1025px) {
    .overview-outer-time .overview-top .overview-top-inner {
        padding: 22px 0px 28px 0px;
    }

    .filter-sec-overview .invoice-filter-inner {
        padding-bottom: 11px;
        margin-bottom: 11px;
    }

    .overview-outer-time {
        margin: 0 20px;
        padding: 0;
    }

    .tab-outer-time ul.tabs {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 479px) and (min-width: 220px) {
    .overview-outer-time .overview-top .overview-top-inner {
        flex-direction: column;
        align-items: baseline;
        grid-gap: 30px;
    }
}


/* Basic styling for the dropdown */
.custom-dropdown1 {
    position: relative;
    min-width: 181px;
}

.placeholder {
    width: 100%;
    height: 100%;
    text-align: left;
    padding-top: 10px;
    font-weight: 300;
}

/* Styling for the selected options area */
/* .selected-options1 {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 32px;
    cursor: pointer;
  } */

/* .selected-option1 {
    display: inline-flex;
    align-items: center;
    padding: 2px 6px;
    margin: 2px;
    background-color: #e0e0e0;
    border-radius: 4px;
  } */

.selected-option1 .remove-option1 {
    margin-left: 4px;
    cursor: pointer;
    font-weight: bold;
}

/* Styling for the dropdown list of options */
.dropdown-list1 {
    /* display: none; */
    position: absolute;
    top: 53px;
    left: 0;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06);
    width: 317px;
    max-height: 343px;
    z-index: 9999;
    max-height: 343px;
    /* Set your desired max height */
    overflow-y: auto;
    padding: 17px;
    padding-top: 1px;
    overflow-x: hidden;
}

.checkbox-label1 {
    display: block;
    padding: 8px;
    cursor: pointer;
}


.checkbox-label1 input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
}

.checkbox-label1 input[type="checkbox"] {
    border: 1px solid #405BFF !important;
    color: #FFFFFF;
    /* color set here so that Windows' High-Contrast Mode can override */
    height: 14px !important;
    position: relative;
    width: 14px !important;
    padding: 0 !important;
    vertical-align: -5px;
    border-radius: 50% !important;
    min-width: 14px;
}

.checkbox-label1 input[type="checkbox"]::before {
    color: #fff;
   
}

.checkbox-label1 input[type="checkbox"]:checked {
    background: #405BFF !important;
    ;
}

.checkbox-label1 input[type="checkbox"]:checked::before {
    /* use `visibility` instead of `display` to avoid recalculating layout */
    visibility: visible;
}

/* input[type="checkbox"]:disabled {
    background: darkgrey;
    border-color: rgb(175, 129, 175);
    color: gray;
  } */
/* Show the dropdown list when the selected-options area is clicked */
.custom-dropdown1:focus-within .dropdown-list1 {
    display: block;
}