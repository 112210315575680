.time-table {
  margin-top: 28px;
  max-width: 100000px;
  overflow: auto;
  background: #ffffff;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
  border-radius: 15px;
}

.time-table table {
  width: 100%;
  border-collapse: collapse;
  max-width: 100000px;
  overflow: auto;
  white-space: nowrap;
}

.time-table th,
.time-table td {
  padding: 20px;
  text-align: left;
  white-space: nowrap;
}

.time-table th {
  background-color: #f5f6f8;
  font-weight: 500;
  font-size: 17px;
  line-height: 20.74px;
  color: #6a7988;
  padding-top: 11px;
  padding-bottom: 12px;
}

.time-table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0px;
  padding-left: 27px;
}

.time-table tr td:first-child {
  padding-left: 27px;
}

.time-table tr td:last-child {
  padding-right: 8px;
}

.time-table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  padding-right: 8px;
}

.time-table td {
  border-bottom: 0.5px solid #d8dde1;
  padding-top: 19px;
  padding-bottom: 22px;
  font-weight: 300;
  font-size: 18px;
  line-height: 21.96px;
  letter-spacing: 1px;
  color: #282828;
}

.time-table img {
  vertical-align: middle;
  margin-left: 5px;
}

.time-table .member-name img {
  margin-left: 0;
}

.time-table tbody tr a {
  font-weight: 300;
  font-size: 15px;
  line-height: 18.3px;
  letter-spacing: 1px;
  color: #ffffff;
  width: 76px;
  height: 31px;
  background: #6479f8;
  border-radius: 8px;
}

.time-table tbody tr a {
  font-weight: 300;
  font-size: 15px;
  line-height: 18.3px;
  letter-spacing: 1px;
  color: #ffffff;
  width: 76px;
  height: 31px;
  background: #6479f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-table tbody tr td span.yes {
  font-weight: 500;
  font-size: 14px;
  line-height: 17.08px;
  letter-spacing: 0.4px;
  color: #7cc273;
  background: #d5f3d8;
  border: 0.2px solid #7cc273;
  border-radius: 100px;
  width: 45px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
}

.time-table tbody tr td span.no {
  font-weight: 500;
  font-size: 14px;
  line-height: 17.08px;
  letter-spacing: 0.4px;
  color: #96a8ba;
  background: #eaeaea;
  border: 0.2px solid #6a7988;
  border-radius: 100px;
  width: 45px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
}

.pagination {
  padding-top: 28px;
  padding-bottom: 42px;
}

.pagination ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
}

.pagination li a {
  cursor: pointer;
  border: 1px solid #6a7988;
  color: #6a7988;
  padding: 5px 10px;
  margin: 0 5.5px;
  border-radius: 7px;
}

.pagination li.active a {
  background-color: #6479f8;
  color: #fff;
}

.back,
.next {
  border: 0 !important;
  font-weight: 300;
  font-size: 15px;
  line-height: 18.3px;
  letter-spacing: 1px;
  color: #6479f8 !important;
  position: relative;
}

.back:before {
  content: "";
  position: absolute;
  background-image: url("../../images/icons/right-ar.svg");
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  left: -6px;
  top: 10px;
  transform: rotate(180deg);
}

.next:after {
  content: "";
  position: absolute;
  background-image: url("../../images/icons/right-ar.svg");
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  right: -6px;
  top: 10px;
}

.pagination li.pagination-item.ellipsis a {
  border: 0 !important;
  padding-bottom: 0px;
}
/* .expense-table .member-name.active span {
  position: absolute;
  max-width: 500px;
  left: 42px;
  word-break: break-all;
  white-space: normal;
  padding: 7px;
  z-index: 9999;
} */
.expense-table td{
  position: relative;
}
.content-outer.Expenses .overview-top-inner {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 50px;
}
.content-outer.Expenses .filter-sec-overview{
  margin-bottom: 36px;
}
.content-outer.Expenses .datatable-team-maintb{
  margin-top: 54px;
}
@media only screen and (max-width: 540px) and (min-width: 220px) {}

@media only screen and (max-width: 767px) {
  .pagination ul {
    justify-content: center;
  }

  .rs-anim-fade.rs-anim-in.rs-picker-date-menu.rs-picker-menu.placement-top-end {
    transform: translate(0%, 0%);
  }

  .tab-mobile-select h4 {
    padding-left: 0;
  }
}

@media print {
  .time-table {
    overflow: visible;
  }
}

/* tracker details */
.tracker-detail-dropdown-pro .cursor,
.form-field-client-outer div.cursor span.custom-dropdown-user {
  background-color: #FFFFFF !important;
  border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
  border-radius: 7px !important;
  font-weight: 300 !important;
  font-size: 19px !important;
  line-height: 23.18px !important;
  letter-spacing: 0.5px !important;
  color: #282828 !important;
  padding: 8px 12px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  display: flex;
  grid-gap: 20px;
  white-space: nowrap;
  flex-direction: row;
  overflow: auto;
  background: url('../../images//icons/down-arrow.svg') no-repeat;
  background-position: right 8px center;
  padding-right: 25px !important;
  height: 43px;
  display: block !important;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 50px !important;
}
.selected-option.custom-dropdown-user-new {
  display: block !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
}
.form-field-client-outer .cursor {
  margin: 6px 0;
  position: relative;
}

.tracker-detail-dropdown-pro .cursor span,
.form-field-client-outer .cursor span {
  height: 25px;
  display: block !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  overflow: hidden !important;
}

.form-field-client.tracker-detail-dropdown.tracker-detail-dropdown-pro .custom-dropdown .client-list {
  max-height: 100% !important;
}

.form-field-client.tracker-detail-dropdown ul.client-list,
.form-field-client-outer ul.client-list {
  max-height: 100% !important;
  overflow: hidden;
}

.form-field-client.tracker-detail-dropdown ul.client-list li,
.form-field-client-outer ul.client-list li {
  white-space: break-spaces;
  word-break: break-word;
}

.ReactModal__Content.ReactModal__Content--after-open.client-modal.project-modal.tracker-overview-modal {
  /* height: 68% !important; */
  padding-right: 20px !important;
}

.tracker-detail-dropdown p.top-cursor {
  background-color: #FFFFFF !important;
  border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
  border-radius: 7px !important;
  font-weight: 300 !important;
  font-size: 19px !important;
  line-height: 23.18px !important;
  letter-spacing: 0.5px !important;
  color: #282828 !important;
  padding: 8px 12px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  display: flex !important;
  grid-gap: 20px;
  white-space: nowrap;
  flex-direction: row;
  overflow: auto;
  background: url('../../images//icons/down-arrow.svg') no-repeat;
  background-position: right 8px center;
  padding-right: 25px !important;
  margin-bottom: 0;
  height: 43px;
}

.tracker-detail-dropdown .date-overview-sec {
  background-color: #FFFFFF !important;
  border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
  border-radius: 7px !important;
  font-weight: 300 !important;
  font-size: 19px !important;
  line-height: 23.18px !important;
  letter-spacing: 0.5px !important;
  color: #282828 !important;
  padding: 4px 12px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  height: 43px;
}

.tracker-detail-dropdown .date-overview-sec input.rs-picker-toggle-textbox {
  border: none !important;
  padding-left: 32px !important;
  outline: none !important;
  box-shadow: none;
}

.form-field-client.tracker-detail-dropdown .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-subtle:focus-visible {
  border: none;
  outline: none;
  background: transparent;
}

.form-field-client.tracker-detail-dropdown .rs-picker-toggle.rs-btn.rs-btn-subtle,
.form-field-client.tracker-detail-dropdown input.rs-picker-toggle-textbox {
  background: transparent;
  outline: none;
}

.form-field-client.tracker-detail-dropdown svg.rs-picker-toggle-caret.rs-icon {
  left: 12px;
}

.form-field-client.tracker-detail-dropdown .rs-picker-toggle.rs-btn.rs-btn-subtle {
  position: relative;
  z-index: 999;
}

.tracker-detail-dropdown .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-subtle {
  box-shadow: none;
}

.tracker-detail-dropdown .time-overview-sec,
.tracker-detail-dropdown .timer-sec-input {
  background-color: #FFFFFF !important;
  border: 0.2px solid rgba(106, 121, 136, 0.7) !important;
  border-radius: 7px !important;
  font-weight: 300 !important;
  font-size: 19px !important;
  line-height: 23.18px !important;
  letter-spacing: 0.5px !important;
  color: #282828 !important;
  padding: 8px 12px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  height: 43px;
}

.start-time-tracker-detail {
  padding-left: 0;
}

.tracker-detail-dropdown .timer-sec-input input.edit-time {
  margin-left: 0;
  padding: 0 !important;
}

.tracker-detail-dropdown .time-overview-sec input {
  width: 92px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.tracker-detail-dropdown {
  position: relative;
}

.tracker-detail-dropdown .custom-dropdown,
.form-field-client-outer .cursor .custom-dropdown {
  position: absolute;
  background: #fff;
  padding: 15px;
  width: 100%;
  left: 0;
  z-index: 9999;
  top: 100%;
  max-height: 250px;
  overflow: auto;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  box-shadow: 3px 3px 4px rgba(106, 121, 136, 0.07);
  border-radius: 10px;
  /* padding-right: 0; */
}
.form-field-client-outer .cursor .custom-dropdown{
  margin-top: 6px !important;
}
.project-detail-modal .form-field-client {
  position: relative;
}
.ReactModal__Content .custom-dropdown {
  height: auto !important;
  max-height: fit-content !important;
}
.tracker-detail-dropdown .search-bar,
.form-field-client-outer .cursor .search-bar {
  width: 100%;
  border-radius: 5px;
  height: 40px;
  padding: 11px 18px;
  background-color: rgba(234, 235, 236, 0.70) !important;
  outline: none;
  margin-bottom: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../images/icons/search-icon-dd.svg) no-repeat;
  background-position: left 18px center;
  cursor: pointer;
  padding-left: 47px;
  border: none;
  font-size: 15px;
  font-family: "halyard-display", sans-serif !important;
}

.form-field-client.tracker-detail-dropdown input.rs-picker-toggle-textbox,
.tracker-detail-dropdown .time-overview-sec input,
.tracker-detail-dropdown .timer-sec-input input.edit-time {
  font-weight: 300 !important;
  font-size: 19px;
  line-height: 23.18px;
  letter-spacing: 0.5px;
  color: #282828;
  text-align: left;
}

.tracker-detail-dropdown .time-overview-sec input:first-child {
  padding-left: 0 !important;
}

.form-field-client.tracker-detail-dropdown.tracker-detail-dropdown-pro>div {
  /* padding: 6px 0; */
}

/* .rs-anim-fade.rs-anim-in.rs-picker-date-menu.rs-picker-menu.placement-top-end {
  transform: translate(-7.5%, 0%);
} */

/* @media only screen and (max-width: 767px) {
  .form-field-client.tracker-detail-dropdown .rs-anim-fade.rs-anim-in.rs-picker-date-menu.rs-picker-menu.placement-top-start {
    transform: translate(0,42%);
}
} */

.project-name-tracker-non-editable{
  background: #FFFFFF;
  border: 0.2px solid rgba(106, 121, 136, 0.7);
  border-radius: 7px;
  font-weight: 300;
  font-size: 19px;
  line-height: 23.18px;
  letter-spacing: 0.5px;
  color: #282828;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
  opacity: 0.6;
  cursor: no-drop;
}
.project-name-tracker-non-editable-outer {
  margin-top: 12px;
}
.project-name-tracker-non-editable-outer label {
  font-weight: 300;
  font-size: 17px;
  line-height: 20.74px;
  color: #6A7988;
  margin-bottom: 9px;
  display: inline-block;
}
.project-name-tracker-non-editable-outer {
  margin-bottom: 20px;
}