.activitylog-main-outter {
    text-align: initial;
    padding-top: 35px;
}

.custom-dropdown-all {
    display: grid;
}

select#memberTeamSelect {
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    min-width: 182px;
    color: #78828D;
    font-weight: 300;
    font-size: 17px;
    padding-left: 12px;
    appearance: none;
    background-image: url(../../images/icons/down-arrow.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-position: calc(100% - 9px) center !important;
    padding-right: 30px;
}

.custom-dropdown-all label {
    color: #282828;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 7px;
}

.search-bar-team input {
    border: none;
    color: #6A7988;
    font-weight: 300;
    font-size: 19px;
    background: transparent;
}

.search-bar-team {
    background: #EAEBEC;
    border-radius: 10px;
    height: 45px;
    max-width: 361px;
    display: flex;
    width: 100%;
    margin-left: 17px;


}

.search-bar-team button {
    background: #EAEBEC;
    border-radius: 10px;
}

.search-userandmail {
    display: flex;
    align-items: flex-end;
    padding: 0px 16.58px 15px 12px;
}

/* TimeTrackerTeamMembers.css */

/* Other styles */

.datatable-team-maintb table {
    width: 100%;
    border-collapse: collapse;
}

.datatable-team-maintb th {
    background-color: #F5F6F8;
    padding: 10px 0;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
}

.datatable-team-maintb td {
    border-bottom: 0.2px solid #D8DDE1;
    /* padding: 25px 35px 25px 27px; */
    color: #282828;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    background: #fff;
    padding: 25px 10px;


}

.datatable-team-maintb {
    padding-top: 38px;
    overflow: scroll;
    margin-bottom: 25px;

}

.tab-inner-time {
    padding-bottom: 50px;
    min-height: 70vh;
}

.datatable-team-maintb th img {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    vertical-align: middle;
}

th.sortable-header {
    color: #6A7988;
    font-weight: 500;
    font-size: 17px;
    padding: 11.3px 10px 12px 10px;
    line-height: 20.74px;
}

.datatable-team-maintb table {
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    min-width: max-content;
}

span.user-image-listingteam img {
    width: 27px;
    margin-right: 15px;
}

.head-tb-of-team-member>tr>th:first-child {
    padding-left: 27px;
}

.body-tb-of-team-member>tr td:first-child {
    padding-left: 27px;


}

.edit-value-changebtn-tic {
    display: flex;
    justify-content: space-between;
    max-width: 149px;
    align-items: baseline;
    grid-gap: 15px;
}

.edit-value-changebtn-tic input {
    max-width: 50px;
    background: #FFFFFF;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    text-align: center;
    font-weight: 300;
}

button.sep-pop-btn {
    background: transparent;
}

element.style {
    background: #F5F6F8;
    border: 0.2px solid #282828;
    border-radius: 6px;
    width: 68px;
    color: #405BFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 32px;
}

.range-activitylog {
    display: flex;
    justify-content: space-between;
}

.range-activitylog-startdate label {
    text-align: left;
    color: #282828;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
}

.range-activitylog-startdate select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../images/icons/down-arrow.svg') no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    padding-right: 30px;
    background-color: #FFFFFF !important;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    padding: 10px 16px 12px 16px;
    color: #78828D;
    font-size: 17px;
    min-width: 182px;
}

.range-activitylog-startdate {
    display: inline-grid;
    flex-direction: column;
}

.range-activitylog-enddate label {
    text-align: left;
    color: #282828;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
}

.range-activitylog-enddate select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../images/icons/down-arrow.svg') no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    padding-right: 30px;
    background-color: #FFFFFF !important;
    border: 0.2px solid rgba(106, 121, 136, 0.7);
    border-radius: 5px;
    height: 43px;
    padding: 10px 16px 12px 16px;
    color: #78828D;
    font-size: 17px;
    min-width: 182px;
}

.range-activitylog-enddate {
    display: inline-grid;
    flex-direction: column;
    padding-left: 28px;
}

.search-userandmail.date-picker {
    border-radius: 6px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 16.58px 15px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.search-userandmail a {
    background: #6479F8;
    border-radius: 6px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 12px 16.58px 15px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

button.main-edit-btn-parent {
    background: #F5F6F8;
    border: 0.2px solid #282828;
    border-radius: 6px;
    width: 68px;
    color: #405BFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
}

.hello-boom-open {
    display: flex;
}

.show-count .filter-activitylog-inner.showing-result.show-result-activitylog-fill {
    position: initial;
    justify-content: flex-end;
    align-items: center;
    display: inline-flex;
    width: 100%;
}

.filter-activitylog-inner.showing-result.show-result-activitylog-fill {
    position: absolute;
    display: flex;
    gap: 10px;
    right: 5%;
}

.filter-activitylog-inner.showing-result.show-result-activitylog-fill select {
    min-width: 44px;
    height: 27px;
    background-color: #FAFAFA;
    border: 0.5px solid #B4B4B4;
    border-radius: 5px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13.42px;
    letter-spacing: 0.5px;
    color: #282828;
    padding-left: 10px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAACXU8ZrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABtSURBVHgBdY67DYAwDEQdpPSMABvACPRpvAEbhA3CCDCJq9Sswgj0KcxFSiSEwI0/93w6IpSItPRRMcYhdwOgs9YemHfn3PYAvKquKaW+YeYTw4S7hxAKEAAsuI/QL1M/i6PUHQBnA/rJMb8z3pC1Mkzst0lyAAAAAElFTkSuQmCC) no-repeat;
    background-position: right 8px center;
    padding-right: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

/* Other styles */
@media screen and (max-width: 991px) {
    .search-userandmail {
        display: block;
    }

    .search-bar-team {
        margin-left: 0px;
        margin-top: 20px;
    }

    .member-main-outter {
        padding: 40px 15px;
    }

    .filter-activitylog-inner.showing-result.show-result-activitylog-fill {
        padding-top: 6px;
        margin: 0px;

    }
}

/* pagination  */
.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    padding-top: 27px;
    padding-bottom: 57px;
}

.pagination li {
    display: inline-block;
    margin-right: 4px;
}

ul.pagination.maininteampag .next:after {
    display: none;
}

li.previous a {
    border: none;
}

.pagination li.active a {
    color: #fff;
}

.pagination li.previous a {
    color: #6479F8;
    font-weight: 300;
    font-size: 15px;
}

.maininteampag li.next.disabled a {
    color: #6479F8;
    font-weight: 300;
    font-size: 15px;
}

.top-log-sec {
    grid-gap: 50px;
    display: flex;
    align-items: center;
}

.top-log-sec .date-picker {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}

.content-outer.activity.log .member-main-outter {
    padding: 35px 0;
}

.top-log-sec .date-picker-outer {
    display: flex;
    grid-gap: 50px;
}

@media only screen and (max-width: 767px) {
    .top-log-sec {
        flex-direction: column;
        justify-content: left;
    }

    .top-log-sec a {
        width: 100%;
    }

    .top-log-sec .date-picker-outer {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .top-log-sec .date-picker-outer {
        flex-direction: column;
    }
}

.custoom-icon-calender span.rs-picker-toggle-value {
    visibility: hidden;
}

.custoom-icon-calender input.rs-picker-toggle-textbox.rs-picker-toggle-read-only,
.custoom-icon-calender .rs-picker-toggle.rs-btn.rs-btn-default {
    background-color: transparent !important;
}

.custom-picker-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0.5px;
    top: 1px;
}

.date-picker-outer {
    position: relative;
    background-color: #fff;
}
.ReactModal__Content .date-picker-outer.date-picker-outer-all {
    position: initial !important;
}

.custoom-icon-calender span.rs-picker-toggle-placeholder {
    visibility: hidden;
}

.custoom-icon-calender svg.rs-picker-toggle-caret.rs-icon {
    background-color: #fff;
}