.add-department-section.top-filter-client-inner .top-filter-client-sort {
    display: flex;
    grid-gap: 14px;
}
.add-department-section.top-filter-client-inner .top-filter-client-sort .custom-dropdown1 {
    min-width: 149px;
    height: 43px;
}
.add-department-section.top-filter-client-inner .top-filter-client-inner-left{
    grid-gap: 0;
    width: 100%;
}
.add-department-section.top-filter-client-inner .top-filter-client-inner-left input{
    border-radius: 10px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
}
.add-department-section.top-filter-client-inner .top-filter-client-sort .search-container input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../../images/icons/search-icon.svg') no-repeat;
    background-position: left 15px center;
    cursor: pointer;
    padding-left: 58px;
    background-color: #EAEBEC;
    border-radius: 10px;
    width: 318px;
    height: 45px;
    border: none;
    font-weight: 300;
    font-size: 19px;
    line-height: 23.18px;
    letter-spacing: 0.5px;
    color: #6A7988;
    padding-right: 15px;
}
.add-department-section.top-filter-client-inner button.add-btn-in-department-b1{
    min-width: 42px;
    min-height: 42px;
}

@media screen and (max-width: 992px){
    .add-department-section.top-filter-client-inner .top-filter-client-sort{
        flex-wrap: wrap;
    }
    .add-department-section.top-filter-client-inner .top-filter-client-inner-left{
        flex-wrap: nowrap;
        flex-direction: row;
    }
}
@media only screen and (max-width: 550px){
    .add-department-section.top-filter-client-inner .top-filter-client-sort{
        justify-content: space-between !important;
    }
}
@media only screen and (max-width: 470px){
    .add-department-section.top-filter-client-inner .top-filter-client-sort .search-container input{
        min-width: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .add-department-section.top-filter-client-inner .top-filter-client-sort{
        width: 100% !important;
    }
    .add-department-section.top-filter-client-inner .top-filter-client-sort .custom-dropdown1 {
        min-width: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .add-department-section.top-filter-client-inner .top-filter-client-sort-search {
        width: 100%;
    }
}
@media screen and (max-width: 703px) {
    .grid-item,
    .department-section-listing input {
        width: 100%;
        max-width: 100% !important;
    }
}