.filter-inner {
    display: flex;
    /* flex-direction: column; */
    align-items: baseline;
    /* grid-gap: 7px; */
}
.button-inner-report a{
    display: flex;
    min-width: 104px;
    height: 44px;
    grid-gap: 8.5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.08px;
    align-items: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    background: #6479f8;
    border-radius: 6px;
    justify-content: center;
}
.invoice-outer-invoice-reprt {
    margin-top: 20px;
    padding-bottom: 0;
    margin-bottom: 27px;
}
.invoice-filter.invoice-filter-report {
    margin-top: 41px;
}
.currency-select .selected-options1 {
    display: flex;
    align-items: center;
    width: 262px;
    height: 43px;
    max-width: 262px;
}
.repot-tatal-box-sec {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr);*/
    grid-template-columns: repeat(auto-fit, minmax(min(260px, 100%), 1fr));
    grid-gap: 19px;
    /* max-width: calc(100% - 284px); */
}
.graph-main-outer-time.graph-main-outer-time-report .top-inner-graph {
    align-items: flex-start;
}
.graph-main-outer-time.graph-main-outer-time-report .top-inner-graph .icon-graph{
    padding-top: 10px;
}
/* comparison graph */
.graph-title{
    text-align: left;
}
.comparison-type{
    margin-top: 13px;
    display: flex;
    grid-gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
}
.comparison-type-inner {
    display: flex;
    grid-gap: 5px;
    align-items: center;
}
.comparison-type-icon {
    display: flex;
}
.comparison-type-title{
    color: #282828;
    font-size: 13px;
    font-weight: 300;
    line-height: 15.86px;
    white-space: nowrap;
}
.top-outer-graph-report ul.recharts-default-legend {
    display: none;
}
.graph-main-outer-time-report ul.recharts-default-legend {
    display: none;
}
.graph-main-outer-time.graph-main-outer-time-report {
    padding: 12px 20px 39px 18px;
    width: calc(100% - 4px);
}
.pie-chart-right{
    /* width: 264px; */
    /* width: 24%; */
}
.filter-comparison-graph {
    display: flex;
    grid-gap: 15.36px;
}
.filter-comparison-graph {
    display: flex;
    grid-gap: 15.36px;
}
.filter-comparison-graph a{
    border-radius: 4px;
    background: #E3E4EE;
    color: #6A7988;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.64px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 21px;
}
.filter-comparison-dot,
.filter-comparison {
    cursor: pointer;
    background: transparent;
    padding: 0;
}
.graph-project-per-hour g.recharts-cartesian-grid-vertical line{
    display: block !important;
}
.graph-main-outer-time.graph-main-outer-time-report.border-line-bottom g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis line.recharts-cartesian-axis-line {
    display: block !important;
    stroke: #D8DDE1;
}
.top-outer-graph-report.top-hours-per-client .comparison-type {
    margin-bottom: 10px;
}
g.recharts-layer.recharts-label-list tspan {
    fill: #282828 !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 12.2px;
    opacity: 1;
}
.content-outer.Reporting.Overview {
    padding-left: 42px;
    padding-right: 42px;
}
/* end comparison graph */
/* pie chart */

.pie-chart-right {
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 16px 21.53px 50px;
    margin-left: 16px;
    width: calc(100% - 16px);
}
.outer-piechart-title {
    color: #454F59;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.96px;
    letter-spacing: 1px;
}
.outer-report-graph-piechart {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0px;
    flex-direction: row;
    margin-top: 20px;
}
/* end pie chart */
.outer-piechart-title.outer-piechart-title-mobile{
    display: none;
}
.client-name-report {
    color: #282828;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 15.86px;
    text-align: left;
    position: absolute;
    bottom: -10px;
}
.repot-tatal-box-sec .overview-title-outer{
    position: relative;
}
.repot-tatal-box-sec .overview-title {
    display: grid;
    grid-gap: 6px;
    /* position: relative; */
}
.repot-tatal-box-sec .overview-rice{
    line-height: 44px;
}
.repot-tatal-box-sec .overview-item{
    padding: 14px 13px 24px 24px;
}
.react-datepicker-popper {
    width: 100%;
}
.react-datepicker-popper .react-datepicker {
    width: 100%;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    display: none;
}
.react-datepicker-popper .react-datepicker__header.react-datepicker-year-header {
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #282828;
    padding: 0 !important;
    margin: 0;
    background: transparent;
    border: none;
    margin-bottom: 15px;
    margin-top: 15px;
}
.react-datepicker-popper .react-datepicker__navigation--previous {
    left: 10px;
}
.react-datepicker-popper .react-datepicker__navigation--next {
    right: 10px;
}
.react-datepicker-popper .react-datepicker__navigation-icon--previous::before{
    right: -3px
}
.react-datepicker-popper .react-datepicker__navigation-icon--next::before{
    left: -3px
}
.react-datepicker-popper .react-datepicker__navigation-icon--next::before,
.react-datepicker-popper .react-datepicker__navigation-icon--previous::before{
    border-color: #405BFF;
    border-width: 1.5px 1.5px 0 0;
    width: 6.5px;
    height: 6.5px;
    top: 10px;
}
.react-datepicker-popper .react-datepicker__year-wrapper{
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    grid-gap: 0px 0;
}
.react-datepicker-popper .react-datepicker__year-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.464571px;
    color: #6A7988;
    width: 55px !important;
    padding: 10px 10px;
    border-radius: 50%;
    height: 55px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.react-datepicker-popper .react-datepicker__year {
    margin: 0;
    text-align: center;
    padding: 0px 5px 20px 5px;
}
.react-datepicker-popper .react-datepicker__navigation{
    top: 21px;
    border: 2px solid #405BFF !important;
    border-radius: 50% !important;
    background-color: transparent !important;
    width: 20px !important;
    height: 20px !important;
    line-height: normal !important;
}
.react-datepicker-popper .react-datepicker__year-text.react-datepicker__year-text--selected {
    background: #405BFF !important;
    border-radius: 50%;
    color: #fff;
}
.react-datepicker-popper .react-datepicker__year-text:hover{
    border-radius: 50%;
}
.react-datepicker-popper .react-datepicker {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
    -webkit-box-shadow: var(--rs-shadow-overlay);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
    box-shadow: var(--rs-shadow-overlay);
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    text-align: left;
    -webkit-transition: none;
    transition: none;
    border: none;
}
.ReactModal__Content .date-picker-outer.date-picker-outer-all input {
    width: 100%;
}
.custoom-icon-calender.custoom-icon-calender-all.custom-date-range .react-datepicker-popper{
    width: auto;
}
/* fliter popup */
.modal.add-bank-account-content.account-details-modal-inner.report-grpah-filter-inner .modal-close-invite.outter-modal-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal.add-bank-account-content.account-details-modal-inner.report-grpah-filter-inner .modal-close-invite.outter-modal-main .modal-invite-head h2{
    color: #282828;
    font-size: 22px;
    font-weight: 500;
    line-height: 22.44px;
    padding-left: 0;
}
.modal.add-bank-account-content.account-details-modal-inner.report-grpah-filter-inner .modal-close-invite.outter-modal-main{
    border-bottom: 1px solid #E8E8E8 !important;
    background: #FBFBFD;
    padding: 22px 9px 9px 14px !important;
    margin-bottom: 22px !important;
    top: 0 !important;
}
.modal.add-bank-account-content.account-details-modal-inner.report-grpah-filter-inner .modal-close-invite.outter-modal-main a{
    background-color: transparent;
}
.ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.add-bank-account-modal.account-details-modal.report-grpah-filter{
    padding: 0 26px 36px 26px !important;
    width: 1260px !important;
    max-width: 1260px !important;
    height: 752px !important;
    transform: translate(-40%, -40%) !important;
    overflow-y: scroll;
    border-radius: 15px;
    background: #FBFBFD !important;
    box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.06) !important;
}
.modal-sub-title{
    color: #454F59;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.96px;
    margin-bottom: 22px;
    padding: 0 19px;
}
.modal.add-bank-account-content.account-details-modal-inner.report-grpah-filter-inner .selected-options1{
    max-width: 100%;
}
.filter-inner-outer {
    padding: 0 21px 23px 23px;
}
.filter-inner-outer label{
    padding-left: 5px;
    color: #6A7988;
    font-size: 17px;
    font-weight: 300;
    line-height: 20.74px;
}
.dropdown-item{
    color: #282828 !important;
    font-size: 17px !important;
    font-weight: 300;
    line-height: 20.74px !important;
    letter-spacing: 1px !important;
    border-bottom: 0.5px solid #d8dde1;
    display: flex;
    align-items: center;
    padding: 16px 5px;
    white-space: break-spaces;
    text-align: left;
    word-break: break-word;
}
.modal-inner-des-outer {
    display: flex;
    grid-gap: 26px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 18px 0 14px;
}
.modal-inner-graph{
    width: calc(100% - 369px);
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    padding: 24px 0 23px 10px;
}
.modal-inner-filter {
    border-radius: 15px;
    border: 0.2px solid rgba(106, 121, 136, 0.70);
    background: #FFF;
    box-shadow: 3px 3px 4px 0px rgba(106, 121, 136, 0.07);
    width: 343px;
}
.modal-inner-filter-tile {
    padding: 18px 22px 15px 22px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 26px;
    color: #454F59;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.96px;
}
.modal-inner-filter.filter-inner-outer {
    padding: 0 21px 20px 23px;
}
.modal-inner-filter .filter-inner.users{
    display: grid;
    grid-gap: 9px;
    margin-bottom: 21px;
}
.modal-inner-graph .top-outer-graph-report.top-hours-per-client{
    padding-left: 16px;
    padding-right: 28px;
    padding-bottom: 23px;
} 
.filter-inner-outer .dropdown-list1 {
    max-width: 100% !important;
    width: 100% !important;
}
.modal-inner-graph ul.recharts-default-legend{
    display: none !important;
}
.modal-bottom-cta {
    display: flex;
    align-items: center;
    grid-gap: 35px;
    margin-top: 31px;
    padding: 0 22px;
}
.modal-bottom-cta button.refresh-modal{
    background: transparent !important;
    color: #405BFF !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19.52px !important;
    letter-spacing: 0.5px !important;
    text-decoration-line: underline !important;
    width: auto !important;
}
.modal-bottom-cta a.cancel-filter-modal{
    color: #282828;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.08px;
    letter-spacing: 0.5px;
    padding: 12px 47px 15px 50px;
    border-radius: 6px;
    border: 0.2px solid #282828;
    background: #F5F6F8;
}
.filter-inner-outer .selected-option1,
.filter-inner-outer .placeholder {
    color: #282828;
    font-size: 19px;
    font-weight: 300;
    line-height: 23.18px;
    letter-spacing: 0.5px;
}
@media screen and (max-width: 1530px) and (min-width: 1350px) {
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.add-bank-account-modal.account-details-modal.report-grpah-filter{
        width: 78vw !important;
        max-width: 100% !important;
        transform: translate(-39%, -40%) !important;
    }
}
@media screen and (max-width: 1349px) and (min-width: 1279px) {
    .modal-inner-graph{
        width: 100%;
    }
    .modal-inner-filter{
        width: 100%;
    }
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.add-bank-account-modal.account-details-modal.report-grpah-filter{
        width: 78vw !important;
        max-width: 100% !important;
        transform: translate(-37.5%, -40%) !important;
    }
    .modal-inner-des-outer{
        flex-direction: column-reverse;
    }
}
@media screen and (max-width: 1278px) and (min-width: 992px) {
    .modal-inner-graph{
        width: 100%;
    }
    .modal-inner-filter{
        width: 100%;
    }
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.add-bank-account-modal.account-details-modal.report-grpah-filter{
        width: 68vw !important;
        max-width: 100% !important;
        transform: translate(-33%, -40%) !important;
    }
}
@media screen and (max-width: 991px) {
    .ReactModal__Content.ReactModal__Content--after-open.client-modal.sprint-modal.add-bank-account-modal.account-details-modal.report-grpah-filter{
        width: 90vw !important;
        max-width: 100% !important;
        transform: translate(0%, 12%) !important;
        padding: 0 0px 36px 15px !important;
    }
    .modal-inner-des-outer{
        flex-direction: column-reverse;
    }
    .modal-inner-graph{
        width: 100%;
    }
    .modal-inner-filter{
        width: 100%;
    }
    .modal-inner-des-outer .comparison-type{
        flex-wrap: wrap;
    }
    
}
@media screen and (max-width: 600px) {
    .filter-comparison-graph{
        justify-content: space-between;
}
.icon-graph {
    /* width: 100%; */
}
}
@media only screen and (max-width: 1100px) and (min-width: 993px) {
    .custoom-icon-calender.custoom-icon-calender-all.custom-date-range .react-datepicker-popper .react-datepicker {
        width: 90%;
        
    }
    .custoom-icon-calender.custoom-icon-calender-all.custom-date-range .react-datepicker-popper{
        text-align: end;
    }
}
@media screen and (max-width: 992px) {
    .react-outer.report-expense,
    .content-outer.Personnel.Reports .tab-inner-time {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .custoom-icon-calender.custoom-icon-calender-all.custom-date-range .react-datepicker-popper {
        text-align: center;
    }
    .custoom-icon-calender.custoom-icon-calender-all.custom-date-range .react-datepicker-popper .react-datepicker {
        width: 90%;
    }
}
@media screen and (min-width: 1100px) {
    .graph-main-outer-time.graph-main-outer-time-report g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis g.recharts-layer.recharts-cartesian-axis-tick tspan {
        font-size: 10px !important;
    }
    .graph-main-outer-time.graph-main-outer-time-report g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis g.recharts-layer.recharts-cartesian-axis-tick tspan{
        font-size: 10px;
    }
}
@media only screen and (max-width: 1274px) and (min-width: 1150px) {
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:last-child,
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(2),
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(3) {
        display: block !important;
    }
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:last-child{
        display: none !important;
    }
}
@media only screen and (min-width: 1275px) {
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:last-child,
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(2) {
        display: block !important;
        flex-direction: column;
    }
    .invoice-outer-invoice .invoice-filter-report .filter-invoice-right{
        display: none !important;
    }
}
@media only screen and (max-width: 1149px) and (min-width: 992px) {
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(1),
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(2),
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:nth-last-child(4) {
        display: block !important;
    }
    .invoice-filter-report .invoice-filter-inner-section.no-flter-all .filter-inner:last-child{
        display: none !important;
    }
}
@media screen and (max-width: 1400px) {
    .repot-tatal-box-sec{
        max-width: 100%;
    }
}
@media screen and (max-width: 1200px) {
    .graph-main-outer-time.graph-main-outer-time-report,
    .pie-chart-right{
        width: 100%;
    }
    .graph-main-outer-time.graph-main-outer-time-report {

        width: 100% !important;
    }
    .outer-report-graph-piechart{
        flex-wrap: wrap;
    }
    .pie-chart-right{
        padding-bottom: 16px;
    }
    .outer-piechart-report {
        flex-direction: row !important;
        align-items: center;
    }
    .outer-piechart-report .outer-piechart-section{
        width: 45%;
    }
    .outer-piechart-report .custom-legend{
        width: 35%;
    }
    .outer-piechart-title.outer-piechart-title-desktop{
        display: none !important;
    }
    .outer-piechart-title.outer-piechart-title-mobile{
        display: block !important;
        margin-bottom: 20px;
    }   
    
}
@media screen and (max-width: 699px) and (min-width: 550px){
    .invoice-outer-invoice.invoice-outer-invoice-reprt {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    .invoice-outer-invoice.invoice-outer-invoice-reprt .invoice-filter.invoice-filter-report {
        margin-top: 0 !important;
    } 
    .invoice-outer-invoice.invoice-outer-invoice-reprt .filter-invoice-right.apply-fliter-right{
        align-items: flex-end;
        justify-content: end;
    }
    .invoice-outer-invoice.invoice-outer-invoice-reprt .filter-invoice-right button{
        margin-bottom: 0 !important;
    }   
}
@media screen and (max-width: 991px) and (min-width: 700px){
    .repot-tatal-box-sec{
        grid-template-columns: 1fr 1fr;
    }
    .invoice-outer-invoice.invoice-outer-invoice-reprt {
        justify-content: space-between;
        display: flex;
        align-items: center;
    }
    .invoice-outer-invoice.invoice-outer-invoice-reprt .invoice-filter.invoice-filter-report {
        margin-top: 0 !important;
    } 
    .invoice-outer-invoice.invoice-outer-invoice-reprt .filter-invoice-right.apply-fliter-right{
        align-items: flex-end;
        justify-content: end;
    }
    .invoice-outer-invoice.invoice-outer-invoice-reprt .filter-invoice-right button{
        margin-bottom: 0 !important;
    }
    .content-outer.Reporting.Overview {
        padding-left: 20px;
        padding-right: 20px;
    }
    
}
@media screen and (max-width: 699px){
    .repot-tatal-box-sec{
        grid-template-columns: 1fr;
    }
    .content-outer.Reporting.Overview {
        padding-left: 20px;
        padding-right: 20px;
    }
    .outer-piechart-report {
        flex-direction: column !important;
    }
    .outer-piechart-report .custom-legend {
        width: 55%;
    }
    
}
@media only screen and (max-width: 1149px) and (min-width: 992px) {
    .repot-tatal-box-sec{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 540px){
    .comparison-type{
        margin-bottom: 0px;
    }
    .graph-main-outer-time.graph-main-outer-time-report .top-inner-graph{
        margin-bottom: 20px;
    }
    .outer-piechart-report .outer-piechart-section {
        width: 100%;
    }
    .outer-piechart-report .custom-legend {
        width: 65%;
        margin: 00 auto;
    }
    .outer-piechart-title.outer-piechart-title-mobile{
        text-align: center;
    }
    .pie-chart-right {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .filter-invoice-right button {
        padding: 13px 30px;
    }
}
@media screen and (max-width: 370px){
    .comparison-type{
        flex-wrap: wrap;
    }
    .outer-piechart-report .custom-legend {
        width: 80%;
        margin: 00 auto;
    }
    .outer-piechart-title.outer-piechart-title-mobile{
        text-align: center;
    }
    .pie-chart-right {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .filter-invoice-right button {
        padding: 13px 30px;
    }
}